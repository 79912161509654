import React, { useEffect, useState } from "react"

import "./userOrders.css"

import UserProfileTemplate  from "../components/userPageTemplate/userPageTemplate"
import UserProfileOrderCard from "./components/userProfileOrderCard/userProfileOrderCard"
import Order                from "assets/icons/pages/userOrders/screen-cart.png"
import IOrder               from "interfaces/order/IOrder"

import ROUPA from "assets/roupa.jpeg"

export default function UserOrders(){
    
    const [orders, setOrders] = useState<IOrder[]>([])

    useEffect(() => {
        setOrders([
            {
                id: "34232342AXFF3",
                status: "paid",
                client: {
                    name: "Carlos",
                    surname: "Silva",
                    email: "carlos.silva@example.com",
                    cpf: "000.000.000-00",
                    orders: undefined
                },
                products: [
                    {
                        quantity: 1,
                        data: {
                            id: "101",
                            name: "Camiseta Estampada",
                            primaryCategory: "menino",
                            secondaryCategory: "conjunto",
                            description: "Camiseta estampada com design moderno e cores vibrantes.",
                            variation: {
                                id: "V201",
                                name: "azul",
                                sku: "SKU101A",
                                color: "Azul",
                                price: 100.00,
                                promotionEnabled: false,
                                promotionPrice: 0,
                                size: "P",
                                images: [ROUPA,ROUPA,ROUPA]
                            }
                        }
                    },
                    {
                        quantity: 1,
                        data: {
                            id: "1021",
                            name: "Camiseta Estampada",
                            primaryCategory: "menino",
                            secondaryCategory: "conjunto",
                            description: "Camiseta estampada com design moderno e cores vibrantes.",
                            variation: {
                                id: "V201",
                                name: "azul",
                                sku: "SKU101A",
                                color: "Azul",
                                price: 100.00,
                                promotionEnabled: false,
                                promotionPrice: 0,
                                size: "P",
                                images: [ROUPA,ROUPA,ROUPA]
                            }
                        }
                    }
                ],
                payment: {
                    method: "pix",
                    data: {
                        total: 100.00
                    }
                },
                shipment: {
                    price: 200,
                    trackCode: "",
                    address: {
                        id: "321",
                        name: "Endereço de Carlos",
                        address1: "Rua das Flores, 123",
                        address2: "Centro",
                        unitNumber: 0,
                        complement: "",
                        city: "São Paulo",
                        state: "SP",
                        postalCode: "01001-000",
                        default: true
                    },
                },
                timeStamp: {
                    date: {
                        day: 12,
                        month: 4,
                        year: 2024
                    },
                    time: {
                        hour: 14,
                        minute: 30
                    }
                }
            }
            
        ])
    },[])
    
    
    return (
        <UserProfileTemplate image={Order} name="Pedidos" navigationUrl="/perfil/pedidos">
            <div id="user-profile-orders-menu">
                {/* <Menu/> */}
            </div>
            <ul id="user-profile-orders-menu-list">
                {
                    orders.map((order) => {
                        return (
                            <UserProfileOrderCard
                                key={order.id}
                                data={order}
                            />
                        )
                    })
                }
            </ul>
        </UserProfileTemplate>
    )
}