import React from "react"

import "./headerMenuEntry.css"

export default function HeaderMenuEntry(props: {data: string, hoverColor: string}){
    
    function hoverColorController(color: string){
        if (color === "pink"){
            return "header-menu-entry-main-pink"
        }
        else if (color === "blue"){
            return "header-menu-entry-main-blue"
        } else {
            return "header-menu-entry-main-default"
        }
    }
    
    return (
        <div id="header-menu-entry-main" className={hoverColorController(props.hoverColor)}>
            <p id="header-menu-entry-txt">
                {props.data}
            </p>
        </div>
    )
}