import React, {useState, useEffect} from "react"

import "./productCategory.css"

import Header from "components/header/header"
import Footer from "components/footer/footer"
import ProductCard from "components/productCard/productCard"

import Background from "assets/backgrounds/category-background-placeholder.svg"

export default function ProductCategory(){
    const [pathList, setPathList] = useState<string[]>([])

    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const productCategoryHeader = window.document.getElementById("product-category-template-header")
        
        if (headerElement && productCategoryHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = productCategoryHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])


    function parsePath(path: string){
        const splitedPath = path.split("/")

        const list = []
        let fullPath = ""

        for (let index = 0; index < splitedPath.length; index++) {
            if (index !== 0) {
                fullPath = fullPath + "/" + splitedPath[index]
                list.push(fullPath)            
            } else {
                list.push("/") 
            }
        }
        setPathList(list)
    }

    return (
        <>  
            <Header/>
            <div id="product-category-template-main">
                <div id="product-category-template-content">
                    <div id="product-category-template-header">
                        <img 
                            id="product-category-template-header-img"
                            src={Background} 
                            alt="" 
                            draggable={false}
                        />
                        <div id="product-category-template-header-title">
                            <div className="product-category-template-header-title-spacer"/>
                            <p id="product-category-template-header-title-txt">
                                Outono
                            </p>
                            <div className="product-category-template-header-title-spacer"/>
                        </div>
                    </div>
                    <ul id="product-category-template-list" style={{minHeight: screenHeight}}>
                   
                    </ul>
                </div>
            </div>
            <Footer/>
        </>
    );
}