import React, {useEffect, useState} from "react"

import "./textFieldLarge.css"

import NumberValidade from "misc/numberValidade"

export default function TextFieldLarge(
    props: {
        label: string, 
        value?: string, 
        disabled?: boolean, 
        monetary?: boolean,
        numeric?: boolean
        onValueChange: Function
    }
){

    const [value, setValue] = useState<string>("")

    useEffect(() => {
        if (props.value === "0"){
            setValue("")
        } else {
            setValue(props.value || "")
        }
    },[props.value])

    function handleValueChange(value: string){
        if (props.numeric === true && props.monetary !== undefined) {
            if (NumberValidade.isNumericString(value)){
                setValue(value)
                props.onValueChange(value)
            }
        } else {
            setValue(value)
            props.onValueChange(value)
        }
    }

    return (
        <div id="text-field-large-main">
            <div id="text-field-large-text-label-container">
                <p id="text-field-large-text-label">
                    {props.label}
                </p>
            </div>
            <div id="text-field-large-text-field-container">
                {
                    props.monetary &&
                    (
                        <p id="text-field-large-currency">
                            R$
                        </p>
                    )
                }
                <textarea 
                    id="text-field-large-text-field" 
                    onChange={(e) => {handleValueChange(e.target.value)}}
                    disabled={props.disabled} 
                    value={value}
                />
            </div>
        </div>
    )
}