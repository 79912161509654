import axios from "axios"
import ICEP from "interfaces/address/ICEP"

export default async function apiCEP(cep: string) {
    console.log(cep)
    try {
        const response = await axios.get(`viacep.com.br/ws/${cep}/json/`)
        const mappedData: ICEP[] = response.data.map((cep: any) => ({
            city: cep.localidade,
            state: cep.uf
        }));
        return mappedData;
    } catch (error) {
        throw error
    }
}