import React, {useEffect, useState, createContext, Dispatch } from "react"

import "./productPage.css"

import { useParams } from "react-router-dom"
import Header from "components/header/header"
import Footer from "components/footer/footer"
import ProductDetails from "./components/productDetails/productDetails"
import UserMenuNavigationElement from "components/userMenuNavigationElement/userMenuNavigationElement"
import Animation from "./components/animation/animation"
import { IProduct, IProductVariation, ISize} from "interfaces/product/IProduct"

import IMG1 from "assets/imagens/produto/imagem1.jpg"
import IMG2 from "assets/imagens/produto/imagem2.2.jpg"
import IMG3 from "assets/imagens/produto/imagem3.jpg"
import IMG4 from "assets/imagens/produto/imagem4.jpg"
import IMG5 from "assets/imagens/produto/imagem5.jpg"

import IMG6 from "assets/imagens/produto-sem-fundo/1.png"
import IMG7 from "assets/imagens/produto-sem-fundo/2.png"
import IMG8 from "assets/imagens/produto-sem-fundo/3.png"
import IMG9 from "assets/imagens/produto-sem-fundo/4.png"
import IMG10 from "assets/imagens/produto-sem-fundo/5.png"


interface IProductPageContext {
    buyMode: string
    setBuyMode: Dispatch<React.SetStateAction<string>>
    runAnimation: boolean
    setRunAnimation: Dispatch<React.SetStateAction<boolean>>
    product: IProduct,
    setProduct: Dispatch<React.SetStateAction<IProduct>>
    variation: IProductVariation
    setVariation: Dispatch<React.SetStateAction<IProductVariation>>
    size: ISize
    setSize: Dispatch<React.SetStateAction<ISize>>
}

export const ProductPageContext = createContext<IProductPageContext | undefined>(undefined);

export default function ProductPage(props: {}){

    const { id } = useParams()

    const [pathList, setPathList] = useState<string[]>([])

    const [buyMode, setBuyMode] = useState<string>("")
    const [runAnimation, setRunAnimation] = useState<boolean>(false)
    const [product, setProduct] = useState<IProduct>(
        {
            id: "",
            name: "",
            url: "",
            enabled: false,
            featured: false,
            primaryCategory: "",
            secondaryCategory: "",
            variations: [],
            description: ""
        }
    )
    const [variation, setVariation] = useState<IProductVariation>(
        {
            id: "",
            name: "",
            sku: "",
            color: "",
            price: 0,
            enabled: false,
            promotionEnabled: false,
            promotionPrice: 0,
            sizes: [],
            images: []
        }
    )
    const [size, setSize] = useState<ISize>(
        {
            id: "",
            size: "",
            quantity: 0,
            enabled: false
        }
    )

    useEffect(() => {
        setProduct(
            {
                id: "P101",
                name: "Vestido Divertido",
                url: "/",
                enabled: true,
                featured: false,
                primaryCategory: "menino",
                secondaryCategory: "conjunto",
                variations: [
                    {
                        id: "V201",
                        name: "cinza",
                        sku: "SKU101A",
                        color: "accade",
                        enabled: true,
                        price: 102.00,
                        promotionEnabled: false,
                        promotionPrice: 0,
                        sizes: [
                            {
                                id: "301",
                                size: "P",
                                quantity: 10,
                                enabled: true
                            },
                            {
                                id: "302",
                                size: "M",
                                quantity: 15,
                                enabled: true
                            },
                            {
                                id: "303",
                                size: "G",
                                quantity: 12,
                                enabled: true
                            },
                            
                        ],
                        images: [IMG1, IMG2, IMG3, IMG4, IMG5]
                    },
                    {
                        id: "V202",
                        name: "azul",
                        sku: "SKU101B",
                        color: "14cade",
                        enabled: true,
                        price: 100.20,
                        promotionEnabled: false,
                        promotionPrice: 0,
                        sizes: [
                            {
                                id: "301",
                                size: "P",
                                quantity: 8,
                                enabled: true
                            },
                            {
                                id: "302",
                                size: "M",
                                quantity: 14,
                                enabled: true
                            },
                            {
                                id: "303",
                                size: "G",
                                quantity: 10,
                                enabled: true
                            },
                            {
                                id: "304",
                                size: "GG",
                                quantity: 6,
                                enabled: true
                            }
                        ],
                        images: [IMG6, IMG7, IMG8, IMG9, IMG10]
                    },
                    {
                        id: "V203",
                        name: "vermelha",
                        sku: "SKU101C",
                        color: "d1173f",
                        enabled: true,
                        price: 100.00,
                        promotionEnabled: true,
                        promotionPrice: 50,
                        sizes: [
                            {
                                id: "301",
                                size: "P",
                                quantity: 9,
                                enabled: true
                            },
                            {
                                id: "302",
                                size: "M",
                                quantity: 13,
                                enabled: true
                            },
                            {
                                id: "303",
                                size: "G",
                                quantity: 11,
                                enabled: true
                            },
                            {
                                id: "304",
                                size: "GG",
                                quantity: 7,
                                enabled: false
                            }
                        ],
                        images: [IMG1, IMG2, IMG3, IMG4, IMG5]
                    }
                ],
                description: "Camiseta estampada com design moderno e cores vibrantes."
            }
        )
    }, [])
    
    useEffect(() => {
        parsePath("/produto")
    },[])

    
    useEffect(() => {
        if (product.id !== ""){
            if (id) {
                const selectedVariation = product.variations.find((variation) => {
                    if (variation.name === id){
                        return variation
                    }
                })
                if (selectedVariation) {
                    setVariation(selectedVariation)
                    setSize(selectedVariation.sizes[0])
                }
            } else {
                setVariation(product.variations[0])
                setSize(product.variations[0].sizes[0])
            }
        }
    },[product, id])


    function parsePath(path: string){
        const splitedPath = path.split("/")

        const list = []
        let fullPath = ""

        for (let index = 0; index < splitedPath.length; index++) {
            if (index !== 0) {
                fullPath = fullPath + "/" + splitedPath[index]
                list.push(fullPath)            
            } else {
                list.push("/") 
            }
        }
        setPathList(list)
    }

    return (
        <ProductPageContext.Provider value={{
                buyMode: buyMode,
                setBuyMode: setBuyMode,
                runAnimation: runAnimation,
                setRunAnimation: setRunAnimation,
                product: product,
                setProduct: setProduct,
                variation: variation,
                setVariation: setVariation,
                size: size,
                setSize: setSize
            }}
        >
            <Header/>
            <div id="product-page-main">
                <div id="product-page-content">
                    <div id="product-page-header">
                        <ul id="product-page-header-navigation-container">
                            {
                                pathList.map((path, index) => {
                                    if (path === pathList[pathList.length-1]){
                                        return (
                                            <UserMenuNavigationElement 
                                                key={index}
                                                data={{url: path, last: true}}
                                            />
                                        )
                                    } else {
                                        return (
                                            <UserMenuNavigationElement 
                                                key={index}
                                                data={{url: path, last: false}}
                                            />
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div id="product-page-body">
                        <div id="product-page-body-details">
                            <ProductDetails data={product}/>
                        </div>
                        {/* <div id="product-page-body-description">
                            <p id="product-page-body-description-txt">
                            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer/>
            <Animation/>
        </ProductPageContext.Provider>
    )
}