import React, {useEffect, useState} from "react"

import "./textfieldUnderline.css"
import { error } from "console"


export default function TextFieldUnderline(
    props: {
        label: string, 
        error?: string,
        value?: string
        placeholder?: string,
        fontSize?: string,
        labelFontSize?: string,
        onValueChange: Function,
        onEdit?: Function,
        onSelected?: Function,
    }
){

    const [value, setValue] = useState<string>("")
    const [selected, setSelected] = useState<boolean>(false)
    const [e, setE] = useState<boolean>(false)

    useEffect(() => {
        setValue(props.value || "")
    },[props.value])


    function handleEnableValueChange(){
        if (props.onEdit){
            props.onEdit()
        }
    }

    function handleValueChange(value: string){
        setValue(value)
        props.onValueChange(value)
    }

    function handleSelected(){
        setSelected(true)
    }

    function handleUnselect(){
        setSelected(false)
    }

    function handleClick(){
        if (props.onSelected){
            props.onSelected()
        }
    }

    return (
        <div id="text-field-underline-main" className={(selected? "text-field-underline-main-selected":"text-field-underline-main")}>
            <div id="text-field-underline-text-label-container">
                <p id="text-field-underline-text-label" style={props.labelFontSize !== ""? {fontSize: props.labelFontSize}: {}}>
                    {props.label}
                </p>    
               {    
                    props.onEdit &&
                    ( 
                        <p id="text-field-underline-text-edit" onClick={handleEnableValueChange}>
                            Editar
                        </p>
                    )
                }
            </div>
            <div id="text-field-underline-text-info">
                <input 
                    id="text-field-underline-text-input" 
                    type="text" 
                    onChange={(e) => {handleValueChange(e.target.value)}} 
                    value={value}
                    placeholder={props.placeholder}
                    onFocus={handleSelected}
                    onBlur={handleUnselect}
                    onMouseDown={handleClick}
                    style={props.fontSize !== ""? {fontSize: props.fontSize}: {}}
                    // onBlur={() => {handleSelected(false)}}
                    // style={{color: (props.error?"red": "")}}
                />
            </div>
        </div>
    )
}