import React, { useState, useEffect, useContext } from "react"

import "./createAddress.css"

import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import IShipmentAddress   from "interfaces/IShipmentAddress"
import { CartContext }    from "pages/user/cart/userCart"
import Dropdown           from "components/inputs/dropdownUnderline/dropdownUnderline"
import apiIBGEStates      from "api/apiIBGEStates"
import apiIBGECity        from "api/apiIBGECity"
import apiCEP             from "api/apiCEP"
import IState             from "interfaces/address/IState"
import ICity              from "interfaces/address/ICity"

interface IShipmentAddressError {
    name:       string;
    address:    string;
    address2:   string;
    unitNumber: string;
    complement: string;
    city:       string;
    state:      string;
    postalCode: string;
}

export default function CreateAddress(props: {onCreateAddressCancelClick?: Function}){

    const cartContext = useContext(CartContext)

    const [stateFetchError, setStateFetchError] = useState<boolean>(false)
    const [error, setError] = useState<IShipmentAddressError>({
        name:       "",
        address:    "",
        address2:   "",
        unitNumber: "",
        complement: "",
        city:       "",
        state:      "",
        postalCode: "",
    })

    const [address, setAddress] = useState<IShipmentAddress>({
        id: "0",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })

    const [stateData, setStateData] = useState<IState[]>([])
    const [cityData, setCityData] = useState<ICity[]>([])

    useEffect(() => {
        apiIBGEStates()
            .then(mappedData => {
                setStateData(mappedData);
            })
            .catch(error => {
                setStateFetchError(true)
                console.error("api error", error);
            });
    }, []);

    useEffect(() => {
        apiIBGECity(address.state)
        .then(mappedData => {
            setCityData(mappedData);
        })
        .catch(error => {
            console.error("api error", error);
        });
    },[address.state])

    useEffect(() =>{
        console.log("renderizou errado")
    },[])

    function handleCancel(){
        // props.onCreateAddressCancelClick()
    }

    function handleFieldChange(field: string, value: string | number) {

        if (field === "state"){
            setAddress((prev) => ({
                ...prev,
                city: ""
            }))
        }

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress

                if (keyTyped === field){
                    setAddress((previous) => ({
                        ...previous,
                        [keyTyped]: value
                    }))
                    setError((previous) => ({
                        ...previous,
                        [keyTyped]: ""
                    }))
                }
            }
        }
    }

    async function handleSave(){

        try {
            const a = await apiCEP(address.postalCode)
            console.log(a)
        } catch (erro) {
            console.log(erro)
        }
        // .then(mappedData => {
        //     console.log(mappedData);
        // })
        // .catch(error => {
        //     console.error("api error", error);
        // });





        let error = false

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress
                if (keyTyped !== "id" && keyTyped !== "default"){
                    if (typeof address[keyTyped] === "string"){
                        if (address[keyTyped] === ""){

                            error = true

                            setError((previous) => ({
                                ...previous,
                                [keyTyped]: "Valor inválido"
                            }))
                        }
                    }
                    if (typeof address[keyTyped] === "number"){
                        if (address[keyTyped] === 0){

                            error = true

                            setError((previous) => ({
                                ...previous,
                                [keyTyped]: "Valor inválido"
                            }))
                        }
                    }
                }
            }
        }

        // if (!error) {

            /* chamada para a API para registrar o endereço no banco de dados */ 


            cartContext?.setOrder((prevOrder) => ({
                ...prevOrder,
                address: address
            }))
            cartContext?.setStatusTracker((prevStatus) => ({
                ...prevStatus,
                payment: true
            }))
            cartContext?.handleNavigation("payment")

            console.log("criar endereço")
        // } else {
        //     console.log ("eeo")
        // }
    }

    return (
        <div id="cart-address-create-address-main">
            <div id="cart-address-create-address-label">
                <p id="cart-address-create-address-label-txt">
                    Para proseguir com sua compra, nos informe um edereço
                </p>
            </div>
            <div id="cart-address-create-address-fields">
                <div className="cart-address-create-address-fields-container">
                    <div className="cart-address-create-address-fields">
                        <TextFieldUnderline
                            placeholder="insira seu nome"
                            error={error.name}
                            label="Nome"
                            onValueChange={(value: string) => {handleFieldChange("name", value)}}
                        />
                    </div>
                    <div className="cart-address-create-address-fields">
                        <TextFieldUnderline
                            label="Endereço"
                            onValueChange={(value: string) => {handleFieldChange("address1", value)}}
                        />
                    </div>
                    <div className="cart-address-create-address-fields">
                        <div className="cart-address-create-address-sub-fields">
                            <TextFieldUnderline
                                label="Número"
                                onValueChange={(value: number) => {handleFieldChange("unitNumber", value)}}
                            />
                        </div>
                        <div className="cart-address-create-address-sub-fields">
                            <TextFieldUnderline
                                label="Complemento"
                                onValueChange={(value: string) => {handleFieldChange("complement", value)}}
                            />
                        </div>
                    </div>
                    <div className="cart-address-create-address-fields">
                        <TextFieldUnderline
                            label="Bairro"
                            onValueChange={(value: number) => {handleFieldChange("address2", value)}}
                        />
                    </div>
                </div>
                <div className="cart-address-create-address-fields-container">
                    <div className="cart-address-create-address-fields">
                        {
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Estado"
                                    showOnClick={true}
                                    options={stateData.map(state => {return state.abbreviation})}
                                    onSelect={(value: string) => {handleFieldChange("state", value)}}
                                />
                            )
                        }
                        {   
                            stateFetchError &&
                            (
                                <TextFieldUnderline
                                    label="Estado"
                                    onValueChange={(value: string) => {handleFieldChange("state", value)}}
                                />
                            )
                        }
                    </div>
                    <div className="cart-address-create-address-fields">
                        {
                            !stateFetchError &&
                            (
                                <Dropdown
                                    label="Cidade"
                                    showOnClick={true}
                                    options={cityData.map(city => {return city.name})}
                                    onSelect={(value: string) => {handleFieldChange("city", value)}}
                                    default={address.city}
                                />
                            )
                        }
                        {
                            stateFetchError &&
                            (
                                <TextFieldUnderline
                                    label="Cidade"
                                    onValueChange={(value: string) => {handleFieldChange("city", value)}}
                                />
                            )
                        }
                    </div>
                    <div className="cart-address-create-address-fields">
                        <TextFieldUnderline
                            label="CEP"
                            onValueChange={(value: string) => {handleFieldChange("postalCode", value)}}
                        />
                    </div>
                    <div id="cart-address-create-address-btns-container">
                        <p id="cart-address-create-address-btns-continue" onClick={handleSave}>
                            Continuar
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}