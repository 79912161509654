import React, {useState, useContext} from "react"

import "./imageList.css"

import Image       from "./components/image/image"
import ImageUpload from "./components/imageUpload/imageUpload"

import { ProductContext } from "pages/admin/product/product/product" 

export default function ImageList(props: {data: string[]}){

    const productContext = useContext(ProductContext)

    function handleAddImage(image: any){
        if (productContext) {
            productContext.setProduct((prevProduct) => {
                const updatedVariations = [...prevProduct!.variations];
                
                const updatedVariation = {
                    ...updatedVariations[productContext.variationIndex],
                    images: [
                        ...updatedVariations[productContext.variationIndex].images, 
                        image
                    ],
                };
    
                updatedVariations[productContext.variationIndex] = updatedVariation;
    
                return {
                    ...prevProduct!,
                    variations: updatedVariations,
                };
            });
        }
    }

    function handleDeleteImage(index: number){
        if (productContext) {
            productContext.setProduct((prevProduct) => {
                const updatedVariations = [...prevProduct!.variations];
                updatedVariations[productContext.variationIndex].images.splice(index, 1);
                return {
                    ...prevProduct!,
                    variations: updatedVariations,
                };
            });
        }
    }

    return (
        <ul id="admin-product-variation-image-list-main">
            <li 
                key={-1}
                id="admin-product-image-list-new-btn"
            >
                <ImageUpload 
                    parent="admin-product-image-list-new-btn"
                    onUploadImg={handleAddImage}
                />
            </li>
            {
                props.data.map((image, index) => {
                    return (
                        <Image
                            key={index}
                            data={image}
                            index={index}
                            onDelete={handleDeleteImage}
                        />
                    )
                })
            }            
        </ul>
    )
}