import React from "react"

import "./orderProductCard.css"

import Roupa from "assets/roupa.jpeg"

import { IProductCart } from "interfaces/product/IProductCart" 

export default function OrderProductCard(props: {product: {quantity: number, data: IProductCart}}){
    return (
        <li id="order-product-card-main">
            <a 
                id="order-product-card-main-link" 
                href="" target="_blank"
                draggable={false}
            >
                <div id="order-product-card-main-img-container">
                    <img 
                        id="order-product-card-main-img"
                        src={Roupa} 
                        alt="" 
                        draggable={false}
                    />
                </div>
                <div id="order-product-card-main-data-container">
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Nome:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.data.name}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Referencia:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.data.variation.sku}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Quantidade:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {/* {props.data.} */}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Variação:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.data.variation.color}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Tamanho:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {props.product.data.variation.size}
                        </p>
                    </div>
                    <div className="order-product-card-main-data">
                        <p className="order-product-card-main-data-label">
                            Preço:
                        </p>
                        <p className="order-product-card-main-data-value">
                            {`${props.product.data.variation.price}`.replace(".", ",")}
                        </p>
                    </div>
                </div>
            </a>
            
        </li>
    )
}