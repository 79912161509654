import React from "react"

import "./image.css"

import Roupa from "assets/roupa.jpeg"
import CloseIcon from "assets/icons/close.png"

export default function Image(props: {data: string, index: number, onDelete: Function}){

    function handleImageDelete(){
        props.onDelete(props.index)
    }

    function encodeImage(image: any){
        return URL.createObjectURL(image)
    }

    return (
        <li id="product-edit-image-list-image-main">
            <div id="product-edit-image-list-image-btns">
                <img 
                    id="product-edit-image-list-image-btns-delete"
                    src={CloseIcon} 
                    alt="" 
                    onClick={handleImageDelete}
                />
            </div>
            <div id="product-edit-image-list-image-container">
                <img 
                    id="product-edit-image-list-image"
                    src={encodeImage(props.data)}
                    alt="" 
                    draggable={false}
                />
            </div>
        </li>
    )
}