import React, {useState, useEffect, ReactNode, createContext} from "react"

import { IProductCart } from "interfaces/product/IProductCart"

interface IAuthProvider {
    isAuthenticated: boolean
    setToken: Function
    getToken: Function
    rmToken: Function
    verify: Function
}

export const AuthContext = createContext<IAuthProvider | undefined>(undefined);

export default function AuthProvider(props: {children?: ReactNode}): JSX.Element{

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

    useEffect(() => {
        const token = localStorage.getItem("JWTPayload")
        if (token === null){
            setIsAuthenticated(false)
        } else {
            setIsAuthenticated(true)
        }
    },[])

    function setToken(token: string) {
        setIsAuthenticated(true)
        localStorage.setItem("JWTPayload", token);
    }

    function getToken(){
        const token = localStorage.getItem("JWTPayload")
        if (token === null){
            setIsAuthenticated(false)
            return ""
        } else {
            setIsAuthenticated(true)
            return token
        }
    }

    function rmToken(){
        localStorage.removeItem("JWTPayload")

        if (localStorage.getItem("JWTPayload") === null){
            setIsAuthenticated(false)
            return true
        } else {
            return false
        }
    }

    function verify(){
        const token = localStorage.getItem("JWTPayload")
        if (token === null){
            return false
        } else {
            return true
        }
    }

    return (
        <AuthContext.Provider value={
            {
                isAuthenticated: isAuthenticated,
                setToken: setToken,
                getToken: getToken,
                rmToken: rmToken,
                verify: verify
            }
        }>
            {props.children}
        </AuthContext.Provider>
    )
}