import React from "react";

import "./categoryCard.css"

export default function CategoryCard() {
    return (
        <li id="category-card-main">

        </li>
    )
}