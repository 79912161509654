import React, { useState, useContext } from "react"

import "./login.css"


import { Link, useNavigate } from "react-router-dom"
import { AuthContext }       from "context/authProvider"
import { apiCaller }         from "api/ApiCaller"
import TextFieldUnderline    from "components/inputs/textfieldUnderline/textfieldUnderline"
import Logo                  from "assets/logo/logoRoundWithBackgroundCropped.png"

export default function Login(props: {onChangeModal: Function}){

    const authContext = useContext(AuthContext)
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>("")


    function handleRegisterBtnClick(){
        props.onChangeModal("login")
    }

    function handleEmailChange(value: string) {
        setEmail(value);
    }

    function handlePasswordChange(value: string) {
        setPassword(value);
    }

    function handleLoginBtnClick () {
        apiCaller.post('/auth/login', {
            email: email,
            password: password
        }).then((response) => {
            
            authContext?.setToken(response.data.data)
            navigate("/")

        }).catch((error) => {
            console.log("erro", error)
        });
    }

    return (
            <div id="login-container">
                <Link id="login-logo-link" to="/" draggable={false}>
                    <img id="login-logo" src={Logo} alt="" draggable={false}/>
                </Link>
                <div id="login-content">
                    <div className="login-input-container">
                        <p id="login-text">
                            Conecte-se
                        </p>
                    </div>
                    <div className="login-input-container">
                        <TextFieldUnderline 
                            label="E-mail"
                            onValueChange={handleEmailChange}
                        />
                    </div>
                    <div className="login-input-container">
                        <TextFieldUnderline 
                            label="Senha"
                            onValueChange={handlePasswordChange}
                        />
                    </div>  
                    <div id="login-warning-message">
                        <p>
                            {/* {response} */}
                        </p>
                    </div>                   
                    <div id="login-input-container-btn">
                        <div id="login-input-container-btn-login" onClick={handleLoginBtnClick}>
                            <p id="login-input-container-btn-login-txt">
                                Entrar
                            </p>
                        </div>
                        <p id="login-input-container-btn-forgot-password">
                            Esqueceu sua senha?
                        </p>
                        <p id="login-input-container-btn-register" onClick={handleRegisterBtnClick}>
                            Criar conta
                        </p>
                    </div>
                </div>
            </div>
    )
}