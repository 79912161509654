import React, { ReactNode, useEffect, useState } from "react"

import "./userPageTemplate.css"

import Header                    from "components/header/header"
import Footer                    from "components/footer/footer"
import UserMenuNavigationElement from "components/userMenuNavigationElement/userMenuNavigationElement"
import IUserMenuNavElement       from "interfaces/IUserMenuNavElement"
import IUserProfileTemplate      from "interfaces/IUserProfileTemplate"

export default function UserProfileTemplate(props: IUserProfileTemplate): JSX.Element {

    const [pathList,     setPathList    ] = useState<string[]>([])
    const [screenHeight, setScreenHeight] = useState<number>(0)
    
    useEffect(() => {
        const headerElement = window.document.getElementById("header-main")
        const pageTemplateHeader = window.document.getElementById("user-page-template-template-header")
        
        if (headerElement && pageTemplateHeader){
            const headerHeight = headerElement.getBoundingClientRect().height
            const pageTemplateHeaderHeight = pageTemplateHeader.getBoundingClientRect().height

            let height = window.screen.availHeight
            height = height - (window.outerHeight - window.innerHeight)
            height = height - headerHeight
            height = height - pageTemplateHeaderHeight
            setScreenHeight(height)
        }
    },[window.innerHeight])
    
    useEffect(() => {
        parsePath(props.navigationUrl)
    },[props.navigationUrl])

    function parsePath(path: string){
        const splitedPath = path.split("/")

        const list = []
        let fullPath = ""

        for (let index = 0; index < splitedPath.length; index++) {
            if (index !== 0) {
                fullPath = fullPath + "/" + splitedPath[index]
                list.push(fullPath)            
            } else {
                list.push("/") 
            }
        }
        setPathList(list)
    }

    return (
        <>  
            <Header/>
            <div id="user-page-template-template-main">
                <div id="user-page-template-template-content">
                    <div id="user-page-template-template-header">
                        <div id="user-page-template-template-header-img-container">
                            <img 
                                id="user-page-template-template-header-img"
                                src={props.image}
                                alt="" 
                                draggable={false}
                            />
                        </div>
                        <div id="user-page-template-template-header-title-container">
                            <p id="user-page-template-template-header-title-txt">
                                {props.name}
                            </p>
                        </div>
                        <ul id="user-page-template-template-header-navigation-container">
                            {
                                pathList.map(path => {
                                    if (path === pathList[pathList.length-1]){
                                        return (
                                            <UserMenuNavigationElement key={path} data={{url: path, last: true}}/>
                                        )
                                    } else {
                                        return (
                                            <UserMenuNavigationElement key={path} data={{url: path, last: false}}/>
                                        )
                                    }
                                })
                            }
                        </ul>
                    </div>
                    <div id="user-page-template-template-list" style={{minHeight: screenHeight}}>
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}