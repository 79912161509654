import React, {useState, useEffect} from "react"

import "./mainPage.css"

import Dropdown            from "components/inputs/dropdownWithoutLabel/dropdownWithoutLabel"
import ICategory           from "interfaces/ICategory"
import ICategoryPage       from "interfaces/ICategoryPage"

import BannerPlaceholder from "assets/backgrounds/banner-placeholder.svg"

interface IMainPageBanner {
    id: string,
    name: string,
    image: any
}

export default function MainPage(){

    const imageRatio = 3.84

    const [isMounted,        setIsMounted       ] = useState<boolean>(false)
    const [banners,          setBanners         ] = useState<IMainPageBanner[]>([])
    const [selectedBanner,   setSelectedBanner  ] = useState<IMainPageBanner>()
    const [imageUploadError, setImageUploadError] = useState<string>("")

    useEffect(() => {
        setBanners(
            [
                {
                    id: "a",
                    name: "banner1",
                    image: undefined
                },
                {
                    id: "b",
                    name: "banner2",
                    image: undefined
                },
            ]
        )
    },[])

    useEffect(() => {
        if (isMounted){
            const tempoEmMilissegundos = 4.5 * 1000;
            const timeoutId = setTimeout(clearError, tempoEmMilissegundos);
            return () => {
                clearTimeout(timeoutId)
            };
        } else {
            setIsMounted(true)
        }
    }, [imageUploadError])

    function clearError(){
        setImageUploadError("")
    }

    function handleCreateBanner(){
        /* chamada da api para criar o banner */
    }

    function handleDeleteBanner(){
        console.log(selectedBanner?.id)
    }

    function handleImageUpload(files: any){
        var reader = new FileReader()
        reader.readAsDataURL(files[0]);

        reader.onload = function (e: any) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                if (image.width / image.height !== imageRatio){
                    setImageUploadError("dimensões da imagem não suportadas utilize imagens com proporções multiplas de 1536 x 400")
                } else {
                    setSelectedBanner((prev) => {
                        if (prev) {
                            return {...prev, image: files[0]}
                        } 
                        else { return { id: "", name: "", image: ""}
                        }
                    })
                    /* chamada pra enviar o novo banner */
                }
            }
        }
    }

    function parseDropdownOption(banners: IMainPageBanner[]){
        const bannerNames = banners.map((banner) => {
            return banner.name
        })
        return bannerNames
    }

    function imageVisualiserParser(value: boolean){
        if (value){
            if (selectedBanner?.image){
                return URL.createObjectURL(selectedBanner?.image)
            } else {
                return BannerPlaceholder
            }
        } else {
            return BannerPlaceholder
        }
    }

    function handleBannerSelect(name: string){
        const filteredBanner = banners.find((banner) => {
            if (banner.name === name){
                return banner
            }
        })
        setSelectedBanner(filteredBanner)
    }

    function upload(){
        document.getElementById("admin-settings-main-page-image-upload-field")?.click()
    }

    return (
        <div id="admin-settings-main-page-main">
            <div id="admin-settings-main-page-label">
                <p id="admin-settings-main-page-labe-txt">
                    pagina de categoria
                </p>
            </div>
            <div id="admin-settings-main-page-selector">
                {
                    selectedBanner &&
                    (
                        <div 
                            id="admin-settings-main-page-delete-banner"
                            onClick={handleDeleteBanner}
                        >

                        </div>
                    )
                }
                <div id="admin-settings-main-page-selector-container">
                    <Dropdown
                        options={parseDropdownOption(banners)}
                        onSelect={handleBannerSelect}
                    />
                </div>
                <div 
                    id="admin-settings-main-page-add-banner"
                    onClick={handleCreateBanner}
                >

                </div>
            </div>
            <div id="admin-settings-main-page-image-container">
                <img 
                    id="admin-settings-main-page-image"
                    src={imageVisualiserParser(selectedBanner?.image !== "")}
                    alt="" 
                    draggable={false}
                />
            </div>
            <div id="admin-settings-main-page-image-warning">
                {
                    imageUploadError &&
                    (
                        <p id="admin-settings-main-page-image-warning-txt">
                            {imageUploadError}
                        </p>
                    )
                }
            </div>
            {
                selectedBanner &&
                (
                    <div id="admin-settings-main-page-image-upload">
                        <div id="admin-settings-main-page-image-upload-btn-container">
                            <input 
                                id={"admin-settings-main-page-image-upload-field"}
                                style={{display: "none"}} 
                                type="file" 
                                onChange={(e) => {handleImageUpload(e.target.files)}}
                            />
                            <div id="admin-settings-main-page-image-upload-btn" onClick={upload}>
                                <p id="admin-settings-main-page-image-upload-btn-txt">
                                    Adicionar Imagem
                                </p>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}