import React, { useState } from "react"
import { Link } from "react-router-dom"

import "./register.css"

import Logo from "assets/logo/logoRoundWithBackgroundCropped.png"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import { apiCaller } from "api/ApiCaller"
import { AxiosResponse } from "axios"

export default function Register(props: {onChangeModal: Function}){
    const [name,           setName          ] = useState('');
    const [email,          setEmail         ] = useState('');
    const [cpf,            setCPF           ] = useState('');
    const [password,       setPassword      ] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    function handleNameChange(value: string) {
        setName(value);
    }

    function handleEmailChange(value: string) {
        setEmail(value);
    }

    function handleCpfChange(value: string) {
        setCPF(value);
    }

    function handlePasswordChange(value: string) {
        setPassword(value);
    }

    function handleRepeatPasswordChange(value: string) {
        setRepeatPassword(value);
    }

    function handleRegisterBtnClick(){
        if (password === repeatPassword) {
            // props.onWaitResponse(true, "register")
            apiCaller.post('/auth/signup', {
                name: name,
                email: email,
                cpf: cpf,
                password: password
            }).then((response: AxiosResponse) => {
                if (response.status === 200) {
                    // localStorage.setItem('Bearer', response.data.data);
                    // alert(`E-mail ${email} cadastrado com sucesso.`);

                }
                // props.onWaitResponse(true, "register")
            }).catch((err) => {
                alert(err);
            });
        }
    }

    function handleLoginBtnClick(){
        props.onChangeModal("register")
    }

    return (
            <div id="register-container">
                <Link id="register-logo-link" to={"/"} draggable={false}>
                    <img id="register-logo" src={Logo} alt="" draggable={false}/>
                </Link>
                <div id="register-content">
                    <div className="register-input-container">
                        <TextFieldUnderline 
                            label="Nome"
                            onValueChange={handleNameChange}
                        />
                        
                    </div>
                    <div className="register-input-container">
                        <TextFieldUnderline 
                            label="E-mail"
                            onValueChange={handleEmailChange}
                        />
                    </div>
                    <div className="register-input-container">
                        <TextFieldUnderline 
                            label="CPF"
                            onValueChange={handleCpfChange}
                        />
                    </div>
                    <div className="register-input-container">
                        <TextFieldUnderline 
                            label="Senha"
                            onValueChange={handlePasswordChange}
                        />
                    </div>
                    <div className="register-input-container">
                        <TextFieldUnderline 
                            label="Confirme sua senha"
                            onValueChange={handleRepeatPasswordChange}
                        />
                    </div>
                </div>
                <div id="register-warning-message">
                        <p>
                            {/* {response} */}
                        </p>
                    </div>   
                <div id="register-btns">
                    <div id="register-btns-register" onClick={handleRegisterBtnClick}>
                        <p id="register-btns-register-txt">
                            Registrar
                        </p>
                    </div>
                    <p id="register-btns-login" onClick={handleLoginBtnClick}>
                        Login
                    </p>
                </div>
            </div>
    )
}