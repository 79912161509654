import React, { useState, useEffect, useContext } from "react"

import "./personalData.css"

import { ProfileDataContext } from "../../userPersonalData"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import { apiCaller } from "api/ApiCaller"
import { AxiosResponse } from "axios"
import { AxiosError } from "axios"
import { AuthContext } from "context/authProvider"

export default function PersonalData(){

    const authContext = useContext(AuthContext)
    const profileDataContext = useContext(ProfileDataContext)

    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)
    const [name, setName] = useState<string>("")
    const [surname, setSurname] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [cpf, setCpf] = useState<string>("")

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                console.log(rootWidth)
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        apiCaller.get("/accounts/", {
            headers: {
                "Authorization": `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            setName(response.data.data.name)
            setSurname(response.data.data.surname)
            setEmail(response.data.data.email)
            setCpf(response.data.data.cpf)

            console.log(response.data.data)
            
        }).catch((error: AxiosError) => {
            // authContext?.rmToken()
        })
    },[])

    function handleEditField(field: string){
        profileDataContext?.setFieldToEdit(field)
        profileDataContext?.navigate("edit")
    }

    return (
        <div id="user-profile-personal-data-content">
            <div id="user-profile-personal-data-content-info1">
                <div className="user-profile-personal-data-content-textfield-container" id="user-profile-personal-data-content-textfield-container-first">
                    <TextFieldUnderline 
                        labelFontSize={resizeTextfield?"1em":""}
                        fontSize={resizeTextfield?"1.1em":""}
                        label="Nome"
                        onEdit={() => {handleEditField("name")}}
                        onValueChange={() => {}}
                        value={name}
                        // errorMessage=""
                    />
                </div>
                <div className="user-profile-personal-data-content-textfield-container">
                    <TextFieldUnderline
                        labelFontSize={resizeTextfield?"1em":""}
                        fontSize={resizeTextfield?"1.1em":""} 
                        label="Sobrenome"
                        onEdit={() => {handleEditField("password")}}
                        onValueChange={() => {}}
                        value={surname}
                        // errorMessage=""
                    />
                    {/* <TextFieldUnderline label="Senha" data="senha123" type="password"/> */}
                </div>
                <div className="user-profile-personal-data-content-textfield-container">
                    <TextFieldUnderline 
                        labelFontSize={resizeTextfield?"1em":""}
                        fontSize={resizeTextfield?"1.1em":""}
                        label="E-mail"
                        onEdit={() => {handleEditField("email")}}
                        onValueChange={() => {}}
                        value={email}
                        // errorMessage=""
                    />
                    {/* <TextFieldUnderline label="E-mail" data="icaro.moreira@ufv.br" type="text"/> */}
                </div>
                <div className="user-profile-personal-data-content-textfield-container">
                    <TextFieldUnderline 
                        labelFontSize={resizeTextfield?"1em":""}
                        fontSize={resizeTextfield?"1.1em":""}
                        label="CPF"
                        onValueChange={() => {}}
                        value={cpf}
                        // errorMessage=""
                    />
                    {/* <TextFieldUnderline label="CPF" data="111111222-50" type="text"/> */}
                </div>
            </div>
            <div id="user-profile-personal-data-content-info2">
                {/* <CheckBox
                    text="Deseja receber novidades de produtos e promoções"
                    onValueChange={() => {}}
                /> */}
            </div>
        </div>
    )
}