import React, { useEffect, useState } from "react"

import Search from "../../assets/icons/search.png"

import "./searchField.css"

export default function SearchField(props: {placeholder: string, showIcon?: boolean, onValueChange: Function, value?: string}){

    const [value, setValue] = useState<string>("")

    useEffect(() => {
        setValue(props.value || "")
    },[props.value])

    function handleValueChange(value: string){
        setValue(value)
        props.onValueChange(value)
    }

    return (
        <div id="search-field-main">
            <input 
                id="search-field-text-field" 
                placeholder={props.placeholder} 
                onChange={(e) => {handleValueChange(e.target.value)}}
                value={value}    
            />
            {
                props.showIcon !== false &&
                (
                    <div id="search-field-text-field-icon-container">
                        <img id="search-field-text-field-icon" src={Search} alt="" draggable={false}/>
                    </div>
                )
            }
        </div>
    )
}