import React, {useEffect, useState} from "react"

import "./maintenance.css"
import Logo from "assets/logo/logoTransparentBackground2.png"
import { Link } from "react-router-dom"

export default function Maintenance(){
    
    const [screenHeight, setScreenHeight] = useState<number>(0)

    useEffect(() => {
        let height = window.screen.availHeight
        height = height - (window.outerHeight - window.innerHeight)
        setScreenHeight(height)
    },[window.innerHeight])
    
    return (
        <div id="maintenance-main" style={{height: screenHeight}}>
            <div id="maintenance-logo">
                <Link
                    id="maintenance-logo-img-container"
                    to={"/"}
                    draggable={false}
                >
                    <img 
                        id="maintenance-logo-img"
                        src={Logo}
                        alt="" 
                        draggable={false}
                    />
                </Link>
            </div>
            <div id="maintenance-info">
                <p id="maintenance-info-txt">
                    Em breve
                </p>
            </div>
        </div>
    )
}