import React, {useState, useEffect, useContext} from "react"

import "./login.css"

import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import { CartContext } from "pages/user/cart/userCart"
import { AuthContext } from "context/authProvider"
import { AuthUserContext } from "pages/user/cart/components/authentication/authentication"
import { apiCaller } from "api/ApiCaller"

interface ILoginData {
    email: string
    password: string
}

export default function Login(props: {onWaitResponse: Function}){

    const authContext = useContext(AuthContext)
    const cartContext = useContext(CartContext)
    const authUserContext = useContext(AuthUserContext)


    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)
    const [email,           setEmail          ] = useState<string>("")
    const [password,        setPassword       ] = useState<string>("")
    const [error,           setError          ] = useState<string>("")

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }
        
        handleResize()
        window.addEventListener("resize", handleResize)
        
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])
    
    function handleEmailChange(value: string){
        setEmail(value)
    }

    function handlePasswordChange(value: string){
        setPassword(value)
    }
    
    function handleForgotPassword(){
        console.log("aa")
        authUserContext?.navigate("recover")
    } 

    function handleRegister(){
        authUserContext?.navigate("register")
    }
    
    function handleResetError(){
        setError("")
    }

    function handleLogin(){

        if (email === "" || password === ""){
            setError("Email ou senha inválido")
        } else {
            let triggerAnimation: boolean = true
            
            setTimeout(() => {
                if (triggerAnimation){
                    props.onWaitResponse(true, "login")
                }
            }, 200)

            apiCaller.post("/auth/login", {
                email: email,
                password: password,
            }).then((response) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "login")
                    triggerAnimation = false
                    
                    cartContext?.setStatusTracker((prevStatus) => ({
                        ...prevStatus,
                        address: true
                    }))

                    console.log(response.data)
                    authContext?.setToken(response.data.data)
                    
                    cartContext?.handleNavigation("address")
                } 
            }).catch((error) => {
                if (triggerAnimation) {
                    props.onWaitResponse(false, "login")
                    triggerAnimation = false
                }
                setError("Email ou senha inválido")
                alert(error)
            })
        }
    }



    return (
        <div id="cart-authentication-login-main">
            <div id="cart-authentication-login-field-container">
                <div className="cart-authentication-login-field">
                    <TextFieldUnderline 
                        labelFontSize={resizeTextfield?"1em":""}
                        fontSize={resizeTextfield?"1.1em":""}
                        label="Email"
                        onValueChange={handleEmailChange}
                        onSelected={handleResetError}
                    />
                </div>
                <div className="cart-authentication-login-field">
                    <TextFieldUnderline 
                        labelFontSize={resizeTextfield?"1em":""}
                        fontSize={resizeTextfield?"1.1em":""}
                        label="Senha"
                        onValueChange={handlePasswordChange}
                        onSelected={handleResetError}
                    />
                </div>
            </div>
            <div id="cart-authentication-warning">
                {
                    error !== "" &&
                    (
                        <p id="cart-authentication-warning-txt">
                            O email ou a senha está incorreto 
                        </p>
                    )
                }
            </div>
            <div id="cart-authentication-login-btn-container">
                <div id="cart-authentication-login-btn" onClick={handleLogin}>
                    <p id="cart-authentication-login-login-btn-txt">
                        Login
                    </p>
                </div>
                <p id="cart-authentication-login-forgot-password-btn" onClick={handleForgotPassword}>
                    Esqueceu sua senha?
                </p>
                <p id="cart-authentication-login-register-btn" onClick={handleRegister}>
                    Registrar
                </p>
            </div>
        </div>
    )
}
