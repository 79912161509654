import React, { useEffect, useState } from "react"

import "./cartAddressListElement.css"

import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import CheckBoxWithoutText from "components/inputs/checkboxWithoutText/checkboxWithoutText"
import IShipmentAddress from "interfaces/IShipmentAddress"

interface IShipmentAddressError {
    name:       string;
    address1:    string;
    address2:   string;
    unitNumber: string;
    complement: string;
    city:       string;
    state:      string;
    postalCode: string;
}

export default function CartAddressListElement(
    props: {
        data: IShipmentAddress,
        isSelected: boolean, 
        onUpdate: Function,
        onSelect: Function
    }
){

    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)

    const [error, setError] = useState<IShipmentAddressError>({
        name:       "",
        address1:    "",
        address2:   "",
        unitNumber: "",
        complement: "",
        city:       "",
        state:      "",
        postalCode: "",
    })

    const [address, setAddress] = useState<IShipmentAddress>({
        id: "0",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })

    const [addressBackup, setAddressBackup] = useState<IShipmentAddress>({
        id: "0",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })


    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        setAddress(props.data)
        setAddressBackup(props.data)
    },[])
    

    function handleEdit(){
        setEdit(true)
    }
    
    function handleCancel(){
        setEdit(false)
        setAddress(addressBackup)
        setError({
            name:       "",
            address1:    "",
            address2:   "",
            unitNumber: "",
            complement: "",
            city:       "",
            state:      "",
            postalCode: "",
        })
        // props.onCreateAddressCancelClick(false)
    }

    function handleFieldChange(field: string, value: string | number) {
        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress

                if (keyTyped === field){
                    setAddress((previous) => ({
                        ...previous,
                        [keyTyped]: value
                    }))
                    setError((previous) => ({
                        ...previous,
                        [keyTyped]: ""
                    }))
                }
            }
        }
    }

    function handleSave(){

        let error = false

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress
                if (keyTyped !== "id" && keyTyped !== "default"){
                    if (typeof address[keyTyped] === "string"){
                        if (address[keyTyped] === ""){

                            error = true

                            setError((previous) => ({
                                ...previous,
                                [keyTyped]: "Valor inválido"
                            }))
                        }
                    }
                    if (typeof address[keyTyped] === "number"){
                        if (address[keyTyped] === 0){

                            error = true

                            setError((previous) => ({
                                ...previous,
                                [keyTyped]: "Valor inválido"
                            }))
                        }
                    }
                }
            }
        }

        if (!error) {
            setAddressBackup(address)
            setEdit(false)
            props.onUpdate(address)
        }
    }


    function handleCheckboxChange(){
        props.onSelect(props.data.id);
    }

    return (
        <li id="card-address-list-element-main">
            <div id="card-address-list-element-resume">
                <div id="card-address-list-element-resume-checkbox">
                    <CheckBoxWithoutText
                        onValueChange={handleCheckboxChange}
                        value={props.isSelected}
                    />
                </div>
                <div id="card-address-list-element-resume-info">
                    <div id="card-address-list-element-resume-info-name">
                        <p id="card-address-list-element-resume-info-name-txt">
                            {addressBackup.name}
                        </p>
                    </div>
                    <div id="card-address-list-element-resume-info-details">
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.address1}
                        </p>
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.unitNumber}
                        </p>
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.complement}
                        </p>
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.address2}
                        </p>
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.city}
                        </p>
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.postalCode}
                        </p>
                        <p className="card-address-list-element-resume-info-details-txt">
                            {addressBackup.state}
                        </p>
                    </div>
                </div>
                <div id="card-address-list-element-resume-btns">
                        {
                            !edit &&
                            (   
                                <p id="card-address-list-element-resume-info-details-edit" onClick={handleEdit}>
                                    Editar
                                </p>
                            )
                        }
                        {
                            edit &&
                            (   <>
                                    <p id="card-address-list-element-resume-info-details-edit" onClick={handleCancel}>
                                        Cancelar
                                    </p>
                                    <p id="card-address-list-element-resume-info-details-save" onClick={handleSave}>
                                        Salvar
                                    </p>
                                </>
                            )
                        }
                </div>
            </div>
            {
                edit &&
                (
                    <div id="card-address-list-element-edit">
                        <div className="card-address-list-element-edit-fields-container">
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Nome"
                                    value={address.name}
                                    onValueChange={(value: string) => {handleFieldChange("name", value)}}
                                />
                            </div>
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Endereço"
                                    value={address.address1}
                                    onValueChange={(value: string) => {handleFieldChange("address", value)}}
                                />
                            </div>
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Número"
                                    value={`${address.unitNumber}`}
                                    onValueChange={(value: number) => {handleFieldChange("unitNumber", value)}}
                                />
                            </div>
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Complemento"
                                    value={address.complement}
                                    onValueChange={(value: string) => {handleFieldChange("complement", value)}}
                                />
                            </div>
                        </div>
                        <div className="card-address-list-element-edit-fields-container">
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Bairro"
                                    value={address.address2}
                                    onValueChange={(value: number) => {handleFieldChange("address2", value)}}
                                />
                            </div>
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Cidade"
                                    value={address.city}
                                    onValueChange={(value: string) => {handleFieldChange("city", value)}}
                                />
                            </div>
                            <div className="card-address-list-element-edit-fields">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Estado"
                                    value={address.state}
                                    onValueChange={(value: string) => {handleFieldChange("state", value)}}
                                />
                            </div>
                            <div className="card-address-list-element-edit-fields card-address-list-element-edit-fields-special">
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="CEP"
                                    value={address.postalCode}
                                    onValueChange={(value: string) => {handleFieldChange("postalCode", value)}}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </li>
    )
}