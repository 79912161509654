import React, {useState, useEffect} from "react"

import "./categoryPage.css"

import Dropdown            from "components/inputs/dropdownWithoutLabel/dropdownWithoutLabel"
import ICategory           from "interfaces/ICategory"
import ICategoryPage       from "interfaces/ICategoryPage"

import CategoryPlaceholder from "assets/backgrounds/category-background-placeholder.svg"


export default function CategoryPage(){

    const imageRatio = 3.84

    const [isMounted, setIsMounted] = useState<boolean>(false)
    const [categoryPages, setCategoryPages] = useState<ICategoryPage[]>([])
    const [selectedCategoryPage, setSelectedCategoryPage] = useState<ICategoryPage>()
    const [imageUploadError, setImageUploadError] = useState<string>("")

    useEffect(() => {
        setCategoryPages(
            [
                {
                    id: "A",
                    category1: "menino",
                    category2: "conjunto",
                    image: undefined
                },
                {
                    id: "B",
                    category1: "menino",
                    category2: "",
                    image: undefined
                }
            ]
        )

    },[])

    useEffect(() => {
        if (isMounted){
            const tempoEmMilissegundos = 4.5 * 1000;
            const timeoutId = setTimeout(clearError, tempoEmMilissegundos);
            return () => {
                clearTimeout(timeoutId)
            };
        } else {
            setIsMounted(true)
        }
    }, [imageUploadError])

    function clearError(){
        setImageUploadError("")
    }

    function handleImageUpload(files: any){

        var reader = new FileReader()
        reader.readAsDataURL(files[0]);

        reader.onload = function (e: any) {
            var image = new Image();
            image.src = e.target.result;
            image.onload = function () {
                if (image.width / image.height !== imageRatio){
                    setImageUploadError("dimensões da imagem não suportadas utilize imagens com proporções multiplas de 1536 x 400")
                } else {
                    setSelectedCategoryPage((prev) => {
                        if (prev) {
                            return {
                                ...prev,
                                image: files[0]
                            }
                        } else {
                            return {
                                id: "",
                                category1: "",
                                category2: "",
                                image: ""
                            };
                        }
                    })
                }
                
            }
        }
    }

    function parseDropdownOption(pages: ICategoryPage[]){
        const pageOtpions = pages.map((page) => {
            if (page.category2 !== ""){
                return `${page.category1} ${page.category2}`
            } else {
                return page.category1
            }
        })
        
        return pageOtpions
    }

    function imageVisualiserParser(value: boolean){
        if (value){
            if (selectedCategoryPage?.image){
                return URL.createObjectURL(selectedCategoryPage?.image)
            } else {
                return CategoryPlaceholder
            }
        } else {
            return CategoryPlaceholder
        }
    }

    function handleCategoryPageSelect(option: string){
        const [category1, category2] = option.split(" ")
        var filteredCategory

        if (category2 !== undefined && category2 !== ""){
            filteredCategory = categoryPages.find((categoryPage) => {
                if (categoryPage.category1 === category1 && categoryPage.category2 === category2){
                    return (categoryPage)
                }
            })
        } else {
            filteredCategory = categoryPages.find((categoryPage) => {
                if (categoryPage.category1 === category1){
                    return (categoryPage)
                }
            })
        }
        setSelectedCategoryPage(filteredCategory)
    }

    function upload(){
        document.getElementById("admin-settings-category-page-image-upload-field")?.click()
    }

    return (
        <div id="admin-settings-category-page-main">
            <div id="admin-settings-category-page-label">
                <p id="admin-settings-category-page-labe-txt">
                    pagina de categoria
                </p>
            </div>
            <div id="admin-settings-category-page-selector">
                <div id="admin-settings-category-page-selector-container">
                    <Dropdown
                        options={parseDropdownOption(categoryPages)}
                        onSelect={handleCategoryPageSelect}
                    />
                </div>
            </div>
            <div id="admin-settings-category-page-image-container">
                <img 
                    id="admin-settings-category-page-image"
                    src={imageVisualiserParser(selectedCategoryPage?.image !== "")}
                    alt="" 
                    draggable={false}
                />
            </div>
            <div id="admin-settings-category-page-image-warning">
                {
                    imageUploadError &&
                    (
                        <p id="admin-settings-category-page-image-warning-txt">
                            {imageUploadError}
                        </p>
                    )
                }
            </div>
            {
                selectedCategoryPage &&
                (
                    <div id="admin-settings-category-page-image-upload">
                        <div id="admin-settings-category-page-image-upload-btn-container">
                            <input 
                                id={"admin-settings-category-page-image-upload-field"}
                                style={{display: "none"}} 
                                type="file" 
                                onChange={(e) => {handleImageUpload(e.target.files)}}
                            />
                            <div id="admin-settings-category-page-image-upload-btn" onClick={upload}>
                                <p id="admin-settings-category-page-image-upload-btn-txt">
                                    Adicionar Imagem
                                </p>
                            </div>
                        </div>
                    </div>

                )
            }
        </div>
    )
}