import React, { useState } from "react"

import "./userProfileOrderCard.css"

import IOrder       from "interfaces/order/IOrder"
import { IPayment } from "interfaces/order/IPayment"
import MenuImage    from "assets/icons/menu.png"

export default function UserProfileOrderCard(props: {data: IOrder}){

    const [showDetail, setShowDetail] = useState<boolean>(false)

    function handleShowDetail(){
        setShowDetail(!showDetail)
    }

    function parsePaymentMethodName(name: string): string {
        if (name === "credit card"){
            return "cartão de crédito"
        } else {
            return "pix"
        }
    }

    function parsePaymentMethod(payment: IPayment): string {
        if ("company" in payment.data) {

            return `${payment.data.company} ${payment.data.instalmentQuantity}x R$ ${payment.data.instalentValue}`

        } else {
            return `1x R$ ${payment.data.total}`
        }
    }   

    function parseStatus(status: string): string {
        if (status === "paid"){
            return "pago"
        } else {
            return ""
        }
    }

    function parseTotal(payment: IPayment): string {
        return `R$ ${payment.data.total}`
    } 

    return (
        <li id="user-profile-order-card-main">
            <div id="user-profile-order-card-resume" style={{borderBottom: (showDetail? "1px solid #d8d5d5":"none")}}>
                <div id="user-profile-order-card-resume-number">
                    <p className="user-profile-order-card-resume-label">
                        N do pedido
                    </p>
                    <p className="user-profile-order-card-resume-info">
                        {props.data.id}
                    </p>
                </div>
                <div id="user-profile-order-card-resume-status">
                    <p className="user-profile-order-card-resume-label">
                        Status
                    </p>
                    <p className="user-profile-order-card-resume-info">
                        {parseStatus(props.data.status)}
                    </p>
                </div>
                <div id="user-profile-order-card-resume-date">
                    <p className="user-profile-order-card-resume-label">
                        Data
                    </p>
                    <p className="user-profile-order-card-resume-info">
                        20/20/2000
                    </p>
                </div>
                <div id="user-profile-order-card-resume-value">
                    <p className="user-profile-order-card-resume-label">
                        Total
                    </p>
                    <p className="user-profile-order-card-resume-info">
                        R$ {props.data.payment?.data.total}
                    </p>
                </div>
                <div id="user-profile-order-card-resume-shipment-code">
                    <p className="user-profile-order-card-resume-label">
                        Rastreio
                    </p>
                    <p className="user-profile-order-card-resume-info">
                        AA123456785BR
                    </p>
                </div>
                <div id="user-profile-order-card-resume-open-detail">
                    <img
                        id="user-profile-order-card-resume-open-detail-img" 
                        src={MenuImage}
                        alt="" 
                        draggable={false}
                        onClick={handleShowDetail}
                    />
                </div>
            </div>
            {
                showDetail &&
                (
                    <div id="user-profile-order-card-detail">
                        <div id="user-profile-order-card-detail-products">
                            <div className="user-profile-order-card-detail-title">
                                <p className="user-profile-order-card-detail-title-txt">
                                    Produtos
                                </p>
                            </div>
                            <ul id="user-profile-order-card-detail-products-list">
                                {
                                    props.data.products?.map((product) => {
                                        return (
                                            <li className="user-profile-card-detail-products-list-element">
                                                <div className="user-profile-card-detail-products-list-element-img-container">
                                                    <img 
                                                        className="user-profile-card-detail-products-list-element-img"
                                                        src={product.data.variation.images[0]}
                                                        alt="" 
                                                        draggable={false}
                                                    />
                                                </div>
                                                <div className="user-profile-card-detail-products-list-element-info">
                                                    <div className="user-profile-card-detail-products-list-element-info-container">
                                                        <p className="user-profile-card-detail-products-list-element-info-name-txt">
                                                            {product.data.name}
                                                        </p>
                                                        <p className="user-profile-card-detail-products-list-element-info-txt">
                                                            {product.data.variation.price}
                                                        </p>
                                                        <p className="user-profile-card-detail-products-list-element-info-txt">
                                                            {product.data.variation.size}
                                                        </p>
                                                        <p className="user-profile-card-detail-products-list-element-info-txt">
                                                            {product.data.variation.color}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div id="user-profile-order-card-detail-payment">
                            <div className="user-profile-order-card-detail-title">
                                <p className="user-profile-order-card-detail-title-txt">
                                    Pagamento
                                </p>
                            </div>
                            <div id="user-profile-order-card-detail-payment-info-container">
                                <div className="user-profile-order-card-detail-payment-info">
                                    <p className="user-profile-order-card-detail-payment-info-label-txt">
                                        Metodo
                                    </p>
                                    {   
                                        props.data.payment?.method &&
                                        (
                                            <p className="user-profile-order-card-detail-payment-info-txt">
                                                {parsePaymentMethodName(props.data.payment?.method)}
                                            </p>
                                        )
                                    }
                                </div>
                                <div className="user-profile-order-card-detail-payment-info">
                                    <p className="user-profile-order-card-detail-payment-info-label-txt">
                                        Informações
                                    </p>
                                    {   
                                        props.data.payment &&
                                        (
                                            <p className="user-profile-order-card-detail-payment-info-txt">
                                                {parsePaymentMethod(props.data.payment)}
                                            </p>
                                        )
                                    }
                                </div>
                                <div className="user-profile-order-card-detail-payment-info">
                                    <p className="user-profile-order-card-detail-payment-info-label-txt">
                                        Total
                                    </p>
                                    {   
                                        props.data.payment &&
                                        (
                                            <p className="user-profile-order-card-detail-payment-info-txt">
                                                {parseTotal(props.data.payment)}
                                            </p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="user-profile-order-card-detail-shipment">
                            <div className="user-profile-order-card-detail-title">
                                <p className="user-profile-order-card-detail-title-txt">
                                    Envio
                                </p>
                            </div>
                            <div id="user-profile-order-card-detail-shipment-info-container">
                                <div className="user-profile-order-card-detail-shipment-info">
                                    <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                        Nome
                                    </p>
                                    <p className="user-profile-order-card-detail-shipment-info-txt">
                                        {props.data.shipment?.address.name}
                                    </p>
                                </div>
                                <div className="user-profile-order-card-detail-shipment-info">
                                    <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                        Endereço
                                    </p>
                                    <p className="user-profile-order-card-detail-shipment-info-txt">
                                        {props.data.shipment?.address.address1}
                                    </p>
                                </div>
                                {
                                    props.data.shipment?.address.complement &&
                                    (
                                        <div className="user-profile-order-card-detail-shipment-info">
                                            <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                                Complemento
                                            </p>
                                            <p className="user-profile-order-card-detail-shipment-info-txt">
                                                {props.data.shipment?.address.complement}
                                            </p>
                                        </div>
                                    )
                                }
                                <div className="user-profile-order-card-detail-shipment-info-row-container">
                                    <div className="user-profile-order-card-detail-shipment-info">
                                        <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                            Numero
                                        </p>
                                        <p className="user-profile-order-card-detail-shipment-info-txt">
                                            {props.data.shipment?.address.unitNumber}
                                        </p>
                                    </div>
                                    <div className="user-profile-order-card-detail-shipment-info">
                                        <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                            Bairro
                                        </p>
                                        <p className="user-profile-order-card-detail-shipment-info-txt">
                                            {props.data.shipment?.address.address2}
                                        </p>
                                    </div>
                                </div>
                                <div className="user-profile-order-card-detail-shipment-info-row-container">
                                    <div className="user-profile-order-card-detail-shipment-info">
                                        <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                            Cidade
                                        </p>
                                        <p className="user-profile-order-card-detail-shipment-info-txt">
                                            {props.data.shipment?.address.city}
                                        </p>
                                    </div>
                                    <div className="user-profile-order-card-detail-shipment-info">
                                        <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                            Estado
                                        </p>
                                        <p className="user-profile-order-card-detail-shipment-info-txt">
                                            {props.data.shipment?.address.state}
                                        </p>
                                    </div>
                                </div>
                                <div className="user-profile-order-card-detail-shipment-info">
                                    <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                        CEP
                                    </p>
                                    <p className="user-profile-order-card-detail-shipment-info-txt">
                                        {props.data.shipment?.address.postalCode}
                                    </p>
                                </div>
                                {
                                    props.data.shipment?.trackCode &&
                                    (
                                        <div className="user-profile-order-card-detail-shipment-info">
                                            <p className="user-profile-order-card-detail-shipment-info-label-txt">
                                                Código de Rastreio
                                            </p>
                                            <p className="user-profile-order-card-detail-shipment-info-txt">
                                                {props.data.shipment?.trackCode}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
        </li>
    )
}