import React, {useState, useEffect, useContext} from "react"

import "./register.css"

import { AxiosResponse } from "axios"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import IClient from "interfaces/IClient"
import CheckBox from "components/inputs/checkbox/checkbox"
import { apiCaller } from "api/ApiCaller"
import { AuthUserContext } from "../../authentication"

export default function Register(props: {onWaitResponse: Function}){

    const authUserContext = useContext(AuthUserContext)

    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)

    const [name,            setName           ] = useState<string>("")
    const [surname,         setSurname        ] = useState<string>("")
    const [email,           setEmail          ] = useState<string>("")
    const [cpf,             setCpf            ] = useState<string>("")
    const [password,        setPassword       ] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    
    function handleNameChange(value: string){
        setName(value)
    }

    function handleSurnameChange(value: string){
        setSurname(value)
    }

    function handleEmailChange(value: string){
        setEmail(value)
    }
    
    function handlePasswordChange(value: string){
        setPassword(value)
    }

    function handleCpfChange(value: string){
        setCpf(value)
    }

    function handleConfirmPasswordChange(value: string){
        setConfirmPassword(value)
    }
    
    function handleEULAAgreement(){
        
    }
    
    function handleLogin(){
        authUserContext?.navigate("login")
    }

    function handleRegister(){

        let triggerAnimation: boolean = true

        setTimeout(() => {
            if (triggerAnimation){
                props.onWaitResponse(true, "register")
            }
        }, 100)
        apiCaller.post("/auth/signup", {
            name: name,
            surname: surname,
            password: password,
            cpf: cpf,
            email: email
        }).then((response) => {
            if (triggerAnimation) {
                props.onWaitResponse(false, "register")
                triggerAnimation = false

                authUserContext?.setUser({
                    name: name,
                    surname: surname,
                    email: email,
                    password: password
                })
                
                authUserContext?.navigate("emailConfirm")
            } 
        }).catch((error) => {
            if (triggerAnimation) {
                props.onWaitResponse(false, "register")
                triggerAnimation = false
                console.log("erro", error)
            }
        })
    }

    return (
        <div id="cart-authentication-register-main">
            <div id="cart-authentication-register-field-container">
                <div className="cart-authentication-register-field-container">
                    <div className="cart-authentication-register-field">
                        <TextFieldUnderline 
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Nome"
                            onValueChange={handleNameChange}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextFieldUnderline 
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Senha"
                            onValueChange={handlePasswordChange}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextFieldUnderline
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""} 
                            label="Email"
                            onValueChange={handleEmailChange}
                        />
                    </div>
                </div>
                <div className="cart-authentication-register-field-container">
                    <div className="cart-authentication-register-field">
                        <TextFieldUnderline 
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Sobrenome"
                            onValueChange={handleSurnameChange}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextFieldUnderline 
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Confirmar Senha"
                            onValueChange={handleConfirmPasswordChange}
                        />
                    </div>
                    <div className="cart-authentication-register-field">
                        <TextFieldUnderline 
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="CPF"
                            onValueChange={handleCpfChange}
                        />
                    </div>
                    
                </div>
            </div>
            <div id="cart-authentication-register-btn-container">
                <div 
                    id="cart-authentication-register-register-btn"
                    onClick={handleRegister}
                >
                    <p id="cart-authentication-register-register-btn-txt">
                        Registrar
                    </p>
                </div>
                <p id="cart-authentication-register-login-btn" onClick={handleLogin}>
                    Login
                </p>
            </div>
        </div>
    )
}