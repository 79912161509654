import React, {useState, useEffect, createContext, Dispatch} from "react"

import "./productList.css"

import Header from "./components/header/header"
import Product from "./components/product/product"
import AdminPageTemplate from "pages/admin/components/adminPageTemplate/adminPageTemplate"

import { IProduct } from "interfaces/product/IProduct"

export default function AdminProductList(){

    const [renderFilteredProducts, setRenderFilteredProducts] = useState<boolean>(false)
    const [products, setProducts] = useState<IProduct[]>([])
    const [filteredProducts, setFilteredProducts] = useState<IProduct[]>([])

    useEffect(() => {
        setProducts(
            [
                {
                    id: "p1",
                    name: "Conjunto Infantil Menina",
                    url: "",
                    primaryCategory: "menina",
                    secondaryCategory: "conjunto",
                    enabled: false,
                    featured: false,
                    variations: [
                        {
                            id: "v1",
                            name: "azul",
                            sku: "sku1",
                            color: "1E90FF", // Azul
                            price: 49.99,
                            enabled: false,
                            promotionEnabled: false,
                            promotionPrice: 0,
                            sizes: [
                                { id: "s1", size: "M", quantity: 15, enabled: false },
                                { id: "s2", size: "L", quantity: 10, enabled: false },
                            ],
                            images: [],
                        },
                        {
                            id: "v2",
                            name: "azul",
                            sku: "sku2",
                            color: "32CD32", // Verde
                            price: 49.99,
                            enabled: false,
                            promotionEnabled: false,
                            promotionPrice: 0,
                            sizes: [
                                { id: "s3", size: "S", quantity: 12, enabled: false },
                                { id: "s4", size: "M", quantity: 8, enabled: false },
                            ],
                            images: [],
                        },
                    ],
                    description: "Conjunto infantil com camiseta e calça.",
                },
                
            ]
        )
    },[])

    function filter(searchValue: string, category: string){
        if (category !== "todos"){
            const filteredByCategory = products.filter((product) => {
                if (product.secondaryCategory.includes(category)){
                    return product
                }
            })
            
            if (searchValue !== ""){
                const filteredByText = filteredByCategory.filter((product) => {
                    if (product.name.toLowerCase().startsWith(searchValue.toLowerCase())){
                        return product
                    }
                })
                setFilteredProducts(filteredByText)
                setRenderFilteredProducts(true)
            } else {
                setFilteredProducts(filteredByCategory)
                setRenderFilteredProducts(true)
            }
        } else {
            if (searchValue !== ""){
                const filteredByText = products.filter((product) => {
                    if (product.name.toLowerCase().startsWith(searchValue.toLowerCase())){
                        return product
                    }
                })
                setFilteredProducts(filteredByText)
                setRenderFilteredProducts(true)
            } else {
                setRenderFilteredProducts(false)
            }
        }
    }

    return (
        <AdminPageTemplate navigationUrl="/admin/produtos">
            <div id="admin-product-list-main">
                <Header 
                    onSearch={filter}
                />
                <div id="admin-product-list-body">
                    {
                        !renderFilteredProducts && products.map((product) => {
                            return (
                                <Product 
                                    key={product.id}
                                    data={product}
                                />
                            )   
                        })
                    }
                    {
                        filteredProducts.map((product) => {
                            return (
                                <Product 
                                    key={product.id}
                                    data={product}
                                />
                            )   
                        })
                    }
                </div>
            </div>
        </AdminPageTemplate>
    )
}