import axios from "axios"
import IState from "interfaces/address/IState"

export default async function apiIBGEStates() {
    try {
        const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        const mappedData: IState[] = response.data.map((state: any) => ({
            id: state.id,
            abbreviation: state.sigla,
            name: state.nome,
            region: {
                id: state.regiao.id,
                abbreviation: state.regiao.sigla,
                name: state.regiao.nome,
            }
        }));
        return mappedData;
    } catch (error) {
        console.error('api error:', error);
        throw error
    }
}