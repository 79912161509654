import React, { useState, useEffect, useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { AuthContext } from "context/authProvider"
import { apiCaller } from "api/ApiCaller"
import Page404 from "pages/404/404"
import { AxiosError, AxiosResponse } from "axios"

export default function AdminProtectedRoute(children: any){

    const authContext = useContext(AuthContext)
    
    const [isAdmin, setIsAdmin] = useState<boolean>(false)

    useEffect(() => {
        apiCaller.get("/accounts/role",
        {
            headers: {
                "Authorization": `Bearer ${authContext?.getToken()}`
            }
        }).then((response: AxiosResponse) => {
            setIsAdmin(true)
            console.log(response)
        }).catch((error: AxiosError) => {
            setIsAdmin(false)
        })
    },[])

    if (authContext?.verify() === true && isAdmin === true) {
        return <Outlet />
    } else {
        return <Page404/>
    }
}
