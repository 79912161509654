import React from "react"

import "./payment.css"

import Selector from "./components/selector/selector"

export default function Payment(){
    return (
        <div id="cart-payment-main">
            <div id="card-payment-method-selector">
                <div id="card-payment-method-selector-label">
                    <p id="card-payment-method-selector-label-txt">
                        Falta pouco, agora selecione uma forma de pagamento
                    </p>
                    {/* <div id="card-payment-method-selector-label-decoration"/> */}
                </div>
                <div id="card-payment-method-selector-container">
                    <Selector
                        options={["boleto", "pix", "cartão"]}
                        onSelect={() => {}}
                        defaultOption="cartão"

                    />
                </div>
            </div>
            <div id="cart-payment-container">

            </div>
        </div>
    )
}