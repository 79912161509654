import React, {useState, useEffect} from "react"

import "./catalog.css"
import "pure-react-carousel/dist/react-carousel.es.css";

import { CarouselProvider, Slider, Slide, Image  } from "pure-react-carousel"
import Header       from "components/header/header"
import Footer       from "components/footer/footer"
import ProductCard  from "components/productCard/productCard"
import CategoryCard from "./components/categoryCard/categoryCard"
import { apiCaller } from "api/ApiCaller";



import Roupa        from "assets/roupa.jpeg"
import X from "./img.png"


import { IProduct } from "interfaces/product/IProduct"

import IMG1 from "assets/imagens/produto/imagem1.jpg"
import IMG2 from "assets/imagens/produto/imagem2.jpg"
import IMG3 from "assets/imagens/produto/imagem3.jpg"
import IMG4 from "assets/imagens/produto/imagem4.jpg"
import IMG5 from "assets/imagens/produto/imagem5.jpg"

import IMG8 from "assets/imagens/produto-sem-fundo/3.png"
import IMG9 from "assets/imagens/produto-sem-fundo/4.png"
import IMG10 from "assets/imagens/produto-sem-fundo/5.png"

import B from "assets/backgrounds/B.svg"
import C from "assets/backgrounds/C.svg"
import { AxiosError, AxiosResponse } from "axios";

export default function Catalog(){

    const [products, setProducts] = useState<IProduct[]>([])
    const [carouselHeight, setCarouslHeight] = useState<number>(500)

    useEffect(() => {
        
        apiCaller.get("/products/", {
        }).then((response: AxiosResponse) => {
            // console.log(response)
        }).catch((error: AxiosError) => {
        })

    }, [])

    useEffect(() => {
        setProducts(
            [
                {
                    id: "P101",
                    name: "Camiseta Estampada",
                    url: "/",
                    enabled: true,
                    featured: false,
                    primaryCategory: "menino",
                    secondaryCategory: "conjunto",
                    variations: [
                        {
                            id: "V201",
                            name: "cinza",
                            sku: "SKU101A",
                            color: "accade",
                            enabled: true,
                            price: 102.00,
                            promotionEnabled: false,
                            promotionPrice: 0,
                            sizes: [
                                {
                                    id: "301",
                                    size: "P",
                                    quantity: 10,
                                    enabled: true
                                },
                                {
                                    id: "302",
                                    size: "M",
                                    quantity: 15,
                                    enabled: true
                                },
                                {
                                    id: "303",
                                    size: "G",
                                    quantity: 12,
                                    enabled: true
                                },
                                
                            ],
                            images: [IMG1, IMG2, IMG3, IMG4, IMG5]
                        },
                        {
                            id: "V202",
                            name: "azul",
                            sku: "SKU101B",
                            color: "14cade",
                            enabled: true,
                            price: 100.22,
                            promotionEnabled: false,
                            promotionPrice: 0,
                            sizes: [
                                {
                                    id: "301",
                                    size: "P",
                                    quantity: 8,
                                    enabled: true
                                },
                                {
                                    id: "302",
                                    size: "M",
                                    quantity: 14,
                                    enabled: true
                                },
                                {
                                    id: "303",
                                    size: "G",
                                    quantity: 10,
                                    enabled: true
                                },
                                {
                                    id: "304",
                                    size: "GG",
                                    quantity: 6,
                                    enabled: true
                                }
                            ],
                            images: [IMG8, IMG9, IMG10]
                        },
                        {
                            id: "V203",
                            name: "vermelha",
                            sku: "SKU101C",
                            color: "d1173f",
                            enabled: true,
                            price: 100.22,
                            promotionEnabled: true,
                            promotionPrice: 50,
                            sizes: [
                                {
                                    id: "301",
                                    size: "P",
                                    quantity: 9,
                                    enabled: true
                                },
                                {
                                    id: "302",
                                    size: "M",
                                    quantity: 13,
                                    enabled: true
                                },
                                {
                                    id: "303",
                                    size: "G",
                                    quantity: 11,
                                    enabled: true
                                },
                                {
                                    id: "304",
                                    size: "GG",
                                    quantity: 7,
                                    enabled: false
                                }
                            ],
                            images: [Roupa, Roupa, Roupa]
                        }
                    ],
                    description: "Camiseta estampada com design moderno e cores vibrantes."
                }
                
            ]
        )
    },[])


    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setCarouslHeight(1200)
                }
                else if (rootWidth <= 900){
                    setCarouslHeight(1000)
                }
                else {
                    setCarouslHeight(500)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return (
        <div id="catalog-main">
            <Header/>
            <div id="catalog-content">
                <div id="catalog-content-highlights-container">
                    <CarouselProvider
                        naturalSlideWidth={1920}
                        naturalSlideHeight={carouselHeight}
                        totalSlides={3}
                        dragEnabled={true}
                        isPlaying={true}
                        // interval={1}
                        className="catalog-content-highlights-carousel"
                    >
                        <Slider>
                            <Slide key={0} index={0}>
                                <Image 
                                    className="slider-offset"
                                    hasMasterSpinner={true} 
                                    src={X}
                                    draggable={false}
                                />
                            </Slide>
                            <Slide key={1} index={1}>
                                <Image className="slider-offset" hasMasterSpinner={true} src={B}/>
                            </Slide>
                            <Slide key={2} index={2}>
                                <Image className="slider-offset" hasMasterSpinner={true} src={C}/>
                            </Slide>
                        </Slider>
                    </CarouselProvider>
                </div>
                <ul id="catalog-content-categories">
                    <CategoryCard/>
                    <CategoryCard/>
                    <CategoryCard/>
                </ul>
                <ul id="catalog-content-products">
                    {
                        products.map((product) => (
                            product.variations.map((variation) => {
                                return (
                                    <ProductCard
                                        key={product.id + variation.color}
                                        data={{
                                            id: product.id + variation.color,
                                            name: product.name,
                                            url: product.url,
                                            description: product.description,
                                            enabled: product.enabled,
                                            featured: product.featured,
                                            primaryCategory: product.primaryCategory,
                                            secondaryCategory: product.secondaryCategory,
                                            variations: [variation]
                                        }}
                                    />
                                )
                            })
                        ))
                    }
                </ul>
            </div>
            <Footer/>
        </div>
    )
}