import React, {useEffect, useState, useContext} from "react"

import "./confirmEmail.css"

import { apiCaller } from "api/ApiCaller"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import { AuthUserContext } from "../../authentication"
import { CartContext } from "pages/user/cart/userCart"
import { AuthContext } from "context/authProvider"

export default function ConfirmEmail(props: {onWaitResponse: Function}){

    const cartContext = useContext(CartContext)
    const authUserContext = useContext(AuthUserContext)
    const authContext = useContext(AuthContext)

    const [isMounted,       setIsMounted      ] = useState<boolean>(false)
    const [code,            setCode           ] = useState<string>("")
    const [error,           setError          ] = useState<string>("")
    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        if (isMounted){
            const tempoEmMilissegundos = 4.6 * 1000;
            const timeoutId = setTimeout(clearError, tempoEmMilissegundos);
            return () => {
                clearTimeout(timeoutId)
            };
        } else {
            setIsMounted(true)
        }
    }, [error])

    function clearError(){
        setError("")
    }

    function handleCodeChange(code: string) {
        setCode(code)
    }

    function handleResendCode(){

    }

    function handleCodeConfirm(){

        let triggerAnimation: boolean = true

        setTimeout(() => {
            if (triggerAnimation){
                props.onWaitResponse(true, "emailConfirm")
            }
        }, 100)

        apiCaller.post("/auth/confirm", 
            {
                email_confirmation_code: code
            },
            {
                params: {
                    email: authUserContext?.user.email
                }
            }
        ).then((response) => {
            console.log(response)
            console.log(authUserContext?.user.email, authUserContext?.user.email)
            apiCaller.post("/auth/login",
                {
                    email: authUserContext?.user.email,
                    password: authUserContext?.user.password
                }
            ).then((response) => {
                
                authContext?.setToken(response.data)
                
                cartContext?.setStatusTracker((prevStatus) => ({
                    ...prevStatus,
                    address: true
                }))
                
                cartContext?.handleNavigation("address")
            }).catch((error) => {

                if (triggerAnimation) {
                    props.onWaitResponse(false, "login")
                    triggerAnimation = false
                    console.log("ai lascou mermo")
                    console.log("erro", error)
                }

            })

        }).catch((error) => {
            if (triggerAnimation) {
                props.onWaitResponse(false, "emailConfirm")
                triggerAnimation = false
                setError("Código inválido")
            }
        })
    }

    return (
        <div id="cart-authentication-confirm-email-main">
            <div id="cart-authentication-confirm-email-input">
                <TextFieldUnderline
                    label="Código"
                    labelFontSize={resizeTextfield?"1em":""}
                    fontSize={resizeTextfield?"1.1em":""}
                    value={code}                    
                    onValueChange={handleCodeChange}
                />
            </div>
            <div id="cart-authentication-confirm-email-adversiment">
                {
                    error &&
                    (
                        <p id="cart-authentication-confirm-email-adversiment-txt">
                            {error}
                        </p>
                    )
                }
            </div>
            <div id="cart-authentication-confirm-email-resend-timer">

            </div>
            <div 
                id="cart-authentication-confirm-email-confirm-btn"
                onClick={handleCodeConfirm}
            >
                <p 
                    id="cart-authentication-confirm-email-confirm-btn-txt"
                >
                    Confirmar
                </p>
            </div>
            <p 
                id="cart-authentication-confirm-email-resend-btn"
                onClick={handleResendCode}
            >
                Reenviar código
            </p>
        </div>
    )
}