import React, { useState, useEffect, useContext, createContext, Dispatch } from "react"

import "./userAddress.css"

import Address from "assets/icons2/hand-package-without-background.png"
import UserProfileTemplate from "../components/userPageTemplate/userPageTemplate"
import UserProfileAddressList from "./components/userProfileAddressList/userProfileAddressList"
import UserProfileAddressNew from "./components/userProfileAddressNew/userProfileAddressNew"
import UserProfileAddressEdit from "./components/userProfileAddressUpdate/userProfileAddressUpdate"
import IShipmentAddress from "interfaces/IShipmentAddress"
import { AuthContext } from "context/authProvider"
import { apiCaller } from "api/ApiCaller"
import { AxiosResponse } from "axios"
import { error } from "console"

interface INavigation {
    addressList: boolean
    createAddress: boolean
    updateAddress: boolean
}

interface IAddressContext {
    handleNavigation: Function
    addresses: IShipmentAddress[]
    setAddresses: Dispatch<React.SetStateAction<IShipmentAddress[]>>
    addressToEdit: IShipmentAddress
    setAddressToEdit: Dispatch<React.SetStateAction<IShipmentAddress>>
}

export const AddressContext = createContext<IAddressContext | undefined>(undefined);

export default function UserAddress() {

    const authContext = useContext(AuthContext)

    const [addresses, setAddresses] = useState<IShipmentAddress[]>([])
    const [addressToEdit, setAddressToEdit] = useState<IShipmentAddress>({
        id: "",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })

    const [navigationController, setNavigationController] = useState<INavigation>({
        addressList: true,
        createAddress: false,
        updateAddress: false
    })

    useEffect(() => {

        const token = `Bearer ${authContext?.getToken()}`

        apiCaller
            .get("/addresses/", {
                headers: {
                    "Authorization": token
                }
            })
            .then((response: AxiosResponse) => {
                setAddresses(response.data.data)
            }).catch((err) => {
                // alert(err);
            });
    },[])

    function handleNavigation(value: string) {
        for (const key in navigationController) {
            if (navigationController.hasOwnProperty(key)) {
                const keyTyped = key as keyof INavigation

                if (keyTyped === value) {
                    setNavigationController((prev) => ({
                        ...prev,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((prev) => ({
                        ...prev,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    function handleSetAsDefault(id: string) {

    }


    function handleSave(address: IShipmentAddress) {

    }

    return (
        <AddressContext.Provider 
            value={{
                handleNavigation: handleNavigation,
                addresses: addresses,
                setAddresses: setAddresses,
                addressToEdit: addressToEdit,
                setAddressToEdit: setAddressToEdit
            }}
        >
            <UserProfileTemplate key={"address"} image={Address} name="Endereços" navigationUrl="/perfil/enderecos">
                {
                    navigationController.addressList &&
                    (
                        <UserProfileAddressList
                            onSetAsDefault={handleSetAsDefault}
                        />
                    )
                }
                {
                    navigationController.createAddress &&
                    (
                        <UserProfileAddressNew/>
                    )
                }
                {
                    navigationController.updateAddress && addressToEdit &&
                    (
                        <UserProfileAddressEdit
                            onEditAddressSave={handleSave}
                        />
                    )
                }
            </UserProfileTemplate>
        </AddressContext.Provider>
    );
}