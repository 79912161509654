import React, { useEffect, useState } from "react"

import "./paid.css"

import OrderCard         from "../components/orderCard/orderCard"
import Details           from "./components/paidOrdersDetail/details"
import IOrder            from "interfaces/order/IOrder"
import AdminPageTemplate from "pages/admin/components/adminPageTemplate/adminPageTemplate"
import CancelOrder       from "../components/cancelOrder/cancelOrder"

export default function AdminPaidOrders(props: {}){

    const [orders, setOrders] = useState<IOrder[]>([])
    const [selectedOrder, setSelectedOrder] = useState<IOrder>()
    const [cancelOrder, setCancelOrder] = useState<boolean>(false)
    const [cancelOrderId, setCancelOrderId] = useState<string>("")


    useEffect(() => {
        setOrders([
            {
                id: "34232342AXFF3",
                status: "payd",
                client: {
                    name: "Carlos",
                    surname: "Silva",
                    email: "carlos.silva@example.com",
                    cpf: "000.000.000-00",
                    orders: undefined
                },
                products: [
                    {
                        quantity: 1,
                        data: {
                            id: "101",
                            name: "Camiseta Estampada",
                            primaryCategory: "menino",
                            secondaryCategory: "conjunto",
                            description: "Camiseta estampada com design moderno e cores vibrantes.",
                            variation: {
                                id: "V201",
                                name: "azul",
                                sku: "SKU101A",
                                color: "Azul",
                                price: 100.00,
                                promotionEnabled: false,
                                promotionPrice: 0,
                                size: "P",
                                images: [""]
                            }

                        }
                    }
                ],
                payment: {
                    method: "pix",
                    data: {
                        total: 100.00
                    }
                },
                shipment: {
                    price: 200,
                    trackCode: "",
                    address: {
                        id: "321",
                        name: "Endereço de Carlos",
                        address1: "Rua das Flores, 123",
                        address2: "Centro",
                        unitNumber: 0,
                        complement: "",
                        city: "São Paulo",
                        state: "SP",
                        postalCode: "01001-000",
                        default: true
                    },
                },
                timeStamp: {
                    date: {
                        day: 12,
                        month: 4,
                        year: 2024
                    },
                    time: {
                        hour: 14,
                        minute: 30
                    }
                }
            }
            
        ])
    },[])

    function handleOrderClick(id: string){
        let order = orders.find(order => order.id === id);
        if (order){
            setSelectedOrder(order)
        }
        setCancelOrder(false)
    }

    function handleCancelOrder(id: string){
        setCancelOrder(true)
        setCancelOrderId(id)
    }

    function handleRufuseCancel(){
        setCancelOrder(false)
    }

    return (
        <AdminPageTemplate navigationUrl="/admin/pedidos-pagos">
            <div id="admin-paid-orders-main">
                <div id="admin-paid-orders-order-list-container">
                    <div id="admin-paid-orders-order-list-label">
                        <p id="admin-paid-orders-order-list-label-number-text" className="admin-paid-orders-order-list-label-text">
                            Número
                        </p>
                        <p id="admin-paid-orders-order-list-label-date-text" className="admin-paid-orders-order-list-label-text">
                            Data
                        </p>
                    </div>
                    <ul id="admin-paid-orders-order-list">
                        {
                            orders.map(order => {
                                return (
                                    <OrderCard 
                                        key={order.id}
                                        data={order} 
                                        onOrderClick={handleOrderClick} 
                                    />
                                )
                            })
                        }
                    </ul>
                </div>
                { 
                    selectedOrder && !cancelOrder &&
                    (
                        <Details
                            data={selectedOrder}
                            onCancelOrder={handleCancelOrder}
                        />
                    )
                }
                {
                    !selectedOrder && !cancelOrder &&
                    (
                        <div id="admin-paid-orders-placeholder">
                            <p id="admin-paid-orders-placeholder-txt">
                                Selecione um pedido no menu ao lado
                            </p>
                        </div>
                    )
                }
                {   
                    cancelOrder &&
                    (
                        <CancelOrder
                            id={cancelOrderId}
                            onRefuseCancel={handleRufuseCancel}
                        />
                    )
                }
            </div>
        </AdminPageTemplate>
    )
}