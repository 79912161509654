import React, {useContext} from "react"

import "./userProfileStatusTrackerItem.css"

import { CartContext } from "pages/user/cart/userCart"

export default function UserProfileStatusTrackerItem(
    props: {
        first?: boolean, 
        disabledImage?: string, 
        name: string,
        element: string,
        active: boolean
    }
){  

    const cartContext = useContext(CartContext)

    function handleNavigation(){
        if (props.active && props.element !== "auth"){
            
            cartContext?.handleNavigation(props.element)
        }
    }

    return (
        <div id="user-profile-status-tracker-item-main" onClick={handleNavigation}>
            {
                !props.first &&
                (
                    <div 
                        id="user-profile-status-tracker-item-trace"
                        style={{backgroundColor: (props.active? "#5386D9":"#D8D5D5")}}
                    />
                )
            }
            {/* <div id="user-profile-status-tracker-item-img-container">
                <img 
                    id="user-profile-status-tracker-item-img"
                    src={props.disabledImage}
                    alt="" 
                    draggable={false}
                    />
            </div> */}
            <div id="user-profile-status-tracker-name">
                <p 
                    id="user-profile-status-tracker-name-txt" 
                    style={{color: (props.active? "#5386D9":"#D8D5D5")}}
                >
                    {props.name}
                </p>
            </div>
        </div>
    )
}