import React, { useState, useEffect, useContext } from "react"

import "./userProfileAddressUpdate.css"

import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import IShipmentAddress   from "interfaces/IShipmentAddress"
import Dropdown           from "components/inputs/dropdownUnderline/dropdownUnderline"
import apiIBGEStates      from "api/apiIBGEStates"
import apiIBGECity        from "api/apiIBGECity"
import IState             from "interfaces/address/IState"
import ICity              from "interfaces/address/ICity"
import { AddressContext } from "pages/user/address/userAddress"

interface IShipmentAddressError {
    name:       string;
    address1:    string;
    address2:   string;
    unitNumber: string;
    complement: string;
    city:       string;
    state:      string;
    postalCode: string;
}

export default function UserProfileAddressEdit(props: {onEditAddressSave: Function}){

    const addressContext = useContext(AddressContext)

    const [stateFetchError, setStateFetchError] = useState<boolean>(false)
    const [resizeTextfield, setResizeTextfield] = useState<boolean>(false)
    const [error, setError] = useState<IShipmentAddressError>({
        name:       "",
        address1:    "",
        address2:   "",
        unitNumber: "",
        complement: "",
        city:       "",
        state:      "",
        postalCode: "",
    })

    const [address, setAddress] = useState<IShipmentAddress>({
        id: "",
        name: "",
        address1: "",
        address2: "",
        unitNumber: 0,
        complement: "",
        city: "",
        state: "",
        postalCode: "",
        default: false
    })
   
    const [stateData, setStateData] = useState<IState[]>([])
    const [cityData, setCityData] = useState<ICity[]>([])


    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")

            if (rootElement) {
                const rootWidth = rootElement.getBoundingClientRect().width
                if (rootWidth <= 500){
                    setResizeTextfield(true)
                } else {
                    setResizeTextfield(false)
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useEffect(() => {
        apiIBGEStates()
            .then(mappedData => {
                setStateData(mappedData);
            })
            .catch(error => {
                setStateFetchError(true)
                console.error("api error", error);
            });
    }, []);

    useEffect(() => {
        if (!stateFetchError){
            apiIBGECity(address.state)
            .then(mappedData => {
                setCityData(mappedData);
            })
            .catch(error => {
                console.error("api error", error);
            });
        }
    },[address.state])

    useEffect(() => {
        if (addressContext){
            setAddress(addressContext?.addressToEdit)
        }
    },[addressContext])

    useEffect(() => {
        if (error.name !== ""){
            setAddress((prev) => ({
                ...prev,
                name: error.name
            }))
        }
        if (error.address1 !== ""){
            setAddress((prev) => ({
                ...prev,
                address1: error.address1
            }))
        }
        if (error.address2 !== ""){
            setAddress((prev) => ({
                ...prev,
                address2: error.address2
            }))
        }
        
        if (error.city !== ""){
            setAddress((prev) => ({
                ...prev,
                city: error.city
            }))
        }
    },[error])

    function handleCancel(){
        addressContext?.handleNavigation("addressList")
    }

    function handleFieldChange(field: string, value: string | number) {

        if (field === "state"){
            setAddress((prev) => ({
                ...prev,
                city: ""
            }))
        }

        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress

                if (keyTyped === field){
                    setAddress((previous) => ({
                        ...previous,
                        [keyTyped]: value
                    }))
                }
            }
        }
    }

    function handleSave(){
        let error = false
        
        for (const key in address){
            if (address.hasOwnProperty(key)){
                const keyTyped = key as keyof IShipmentAddress
                if (keyTyped !== "id" && keyTyped !== "default"){
                    if (typeof address[keyTyped] === "string"){
                        if (address[keyTyped] === ""){
                            error = true

                            setError((previous) => ({
                                ...previous,
                                [keyTyped]: "Valor inválido"
                            }))
                        }
                    }
                    if (typeof address[keyTyped] === "number"){
                        if (address[keyTyped] === 0){

                            error = true

                            setError((previous) => ({
                                ...previous,
                                [keyTyped]: "Valor inválido"
                            }))
                        }
                    }
                }
            }
        }

        if (!error) {
            props.onEditAddressSave(address)            
        }
    }

    function handleResetError(field: string){
        if (field === "name" && error.name !== ""){
            setAddress((previous) => ({
                ...previous,
                name: ""
            }))
            setError((previous) => ({
                ...previous,
                name: ""
            }))
        }
        if (field === "address1" && error.address1 !== ""){
            setAddress((previous) => ({
                ...previous,
                address1: ""
            }))
            setError((previous) => ({
                ...previous,
                address1: ""
            }))
        }
        if (field === "address2" && error.address2 !== ""){
            setAddress((previous) => ({
                ...previous,
                address2: ""
            }))
            setError((previous) => ({
                ...previous,
                address2: ""
            }))
        }
        if (field === "city" && error.city !== ""){
            setAddress((previous) => ({
                ...previous,
                city: ""
            }))
            setError((previous) => ({
                ...previous,
                city: ""
            }))
        }
        if (field === "state" && error.state !== ""){
            setAddress((previous) => ({
                ...previous,
                state: ""
            }))
            setError((previous) => ({
                ...previous,
                state: ""
            }))
        }
        if (field === "unitNumber" && error.unitNumber !== ""){
            setAddress((previous) => ({
                ...previous,
                unitNumber: 0
            }))
            setError((previous) => ({
                ...previous,
                unitNumber: ""
            }))
        }
        if (field === "complement" && error.complement !== ""){
            setAddress((previous) => ({
                ...previous,
                complement: ""
            }))
            setError((previous) => ({
                ...previous,
                complement: ""
            }))
        }
        if (field === "postalCode" && error.postalCode !== ""){
            setAddress((previous) => ({
                ...previous,
                postalCode: ""
            }))
            setError((previous) => ({
                ...previous,
                postalCode: ""
            }))
        }
    }

    return (
        <div id="user-profile-address-update">
            <div id="user-profile-address-update-label">
                <p id="user-profile-address-update-label-txt">
                </p>
            </div>
            <div id="user-profile-address-update-fields">
                <div className="user-profile-address-update-fields-container">
                    <div className="user-profile-address-update-fields">
                        <TextFieldUnderline
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Nome"
                            value={addressContext?.addressToEdit.name}
                            onValueChange={(value: string) => {handleFieldChange("name", value)}}
                            onSelected={() => {handleResetError("name")}}
                        />
                    </div>
                    <div className="user-profile-address-update-fields">
                        <TextFieldUnderline
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Endereço"
                            value={addressContext?.addressToEdit.address1}
                            onValueChange={(value: string) => {handleFieldChange("address1", value)}}
                            onSelected={() => {handleResetError("address1")}}
                        />
                    </div>
                    <div className="user-profile-address-update-fields">
                        <div className="user-profile-address-update-sub-fields">
                            <TextFieldUnderline
                                labelFontSize={resizeTextfield?"1em":""}
                                fontSize={resizeTextfield?"1.1em":""}
                                label="Número"
                                value={`${addressContext?.addressToEdit.unitNumber}`}
                                onValueChange={(value: number) => {handleFieldChange("unitNumber", value)}}
                                onSelected={() => {handleResetError("unitNumber")}}
                            />
                        </div>
                        <div className="user-profile-address-update-sub-fields">
                            <TextFieldUnderline
                                labelFontSize={resizeTextfield?"1em":""}
                                fontSize={resizeTextfield?"1.1em":""}
                                label="Complemento"
                                value={addressContext?.addressToEdit.complement}
                                onValueChange={(value: string) => {handleFieldChange("complement", value)}}
                                onSelected={() => {handleResetError("complement")}}
                            />
                        </div>
                    </div>
                    <div className="user-profile-address-update-fields">
                        <TextFieldUnderline
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="Bairro"
                            value={addressContext?.addressToEdit.address2}
                            onValueChange={(value: number) => {handleFieldChange("address2", value)}}
                            onSelected={() => {handleResetError("address2")}}
                        />
                    </div>
                </div>
                <div className="user-profile-address-update-fields-container">
                    <div className="user-profile-address-update-fields">
                        {   
                            !stateFetchError &&
                            (
                                <Dropdown
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Estado"
                                    showOnClick={true}
                                    options={stateData.map(state => {return state.abbreviation})}
                                    onSelect={(value: string) => {handleFieldChange("state", value)}}
                                    default={address.state}
                                />
                            )
                        }
                        {   
                            stateFetchError &&
                            (
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Estado"
                                    value={address.state}
                                    onValueChange={(value: string) => {handleFieldChange("state", value)}}
                                    onSelected={() => {handleResetError("address2")}}
                                />
                            )
                        }
                    </div>
                    <div className="user-profile-address-update-fields">
                        {
                            !stateFetchError &&
                            (
                                <Dropdown
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Cidade"
                                    showOnClick={true}
                                    options={cityData.map(city => {return city.name})}
                                    onSelect={(value: string) => {handleFieldChange("city", value)}}
                                    default={address.city}
                                />
                            )
                        }
                        {
                            stateFetchError &&
                            (
                                <TextFieldUnderline
                                    labelFontSize={resizeTextfield?"1em":""}
                                    fontSize={resizeTextfield?"1.1em":""}
                                    label="Cidade"
                                    value={address.city}
                                    onValueChange={(value: string) => {handleFieldChange("city", value)}}
                                    onSelected={() => {handleResetError("address2")}}
                                />
                            )
                        }
                    </div>
                    <div className="user-profile-address-update-fields">
                        <TextFieldUnderline
                            labelFontSize={resizeTextfield?"1em":""}
                            fontSize={resizeTextfield?"1.1em":""}
                            label="CEP"
                            value={address.postalCode}
                            onValueChange={(value: string) => {handleFieldChange("postalCode", value)}}
                            onSelected={() => {handleResetError("postalCode")}}
                        />
                    </div>
                    <div id="user-profile-address-update-btns-container">
                        <p className="user-profile-address-update-btns-save" onClick={handleSave}>
                            Salvar
                        </p>
                        <p className="user-profile-address-update-btns" onClick={handleCancel}>
                            Cancelar
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}