import React, {useState, useEffect, useContext} from "react"
import { useNavigate } from "react-router-dom"

import "./variation.css"

import { IProductVariation } from "interfaces/product/IProduct"
import { ProductPageContext } from "pages/productPage/productPage"
import DiscountIcon from "assets/icons/discount.png"

export default function Option(props: {data: IProductVariation}){

    const navigate = useNavigate()

    const productPageContext = useContext(ProductPageContext)
    const [selected, setSelected] = useState<boolean>(false)

    useEffect(() => {
        if (productPageContext){
            if (props.data.id === productPageContext.variation.id){
                setSelected(true)
            } else {
                setSelected(false)
            }
        }
    },[productPageContext?.size])


    function handleVariationSelect(){
        if (productPageContext){
            let selectedVariation = productPageContext.product.variations.find((variation) => {
                if (variation.id === props.data.id){
                    return variation
                }
            })
            if (selectedVariation){
                productPageContext.setVariation(selectedVariation)
                productPageContext.setSize(selectedVariation.sizes[0])
                
                if (props.data.id === productPageContext.variation.id){
                    setSelected(true)
                } else {
                    setSelected(false)
                }
            }
        }
        navigate("/produto/" + props.data.name)
    }
    return (
        <>
            <li
                id="product-details-variation-main" 
                style={{backgroundColor: `#${props.data.color}`}}
                onClick={handleVariationSelect}
                className={selected === true? "product-details-variation-main-selected": "product-details-variation-main-unselected"}
            >
                {
                    props.data.promotionEnabled &&
                    (
                        <div id="product-details-variation-notification">
                            <img
                                id="product-details-variation-notification-img" 
                                src={DiscountIcon}
                                alt="" 
                                draggable={false}
                            />
                        </div>
                    )
                }
            </li>
            
        </>
    )
}