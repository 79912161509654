import React, {useState, useEffect, useContext} from "react"
import { Link } from "react-router-dom"

import "./header.css"

import SearchField from "components/searchField/searchField"
import Dropdown from "components/inputs/dropdownWithoutLabel/dropdownWithoutLabel"

export default function Header(props: {onSearch: Function}){

    const [searchTerm, setSearchTerm] = useState<string>("")
    const [searchCategory, setSearchCategory] = useState<string>("")
    const [categories, setCategories] = useState<string[]>([])
    const [orderTypes, setOrderTypes] = useState<string[]>([])

    useEffect(() => {
        setCategories(
            [
                "conjunto",
                "tshirt",
                "calça",
                "short",
                "social" ,
                "inverno" ,
                "moda praia",
                "fantasia",
                "cuecas",
                "macacão" ,
                "vestido" ,
                "saia", 
                "blusa",
                "calcinha",
                "todos"
            ]
        )

        setOrderTypes([
            "preço",
            "estoque",
            "nenhum"
        ])

        setSearchCategory("todos")
    },[])

    function handleSearch(value: string){
        props.onSearch(value, searchCategory)
        setSearchTerm(value)
    }

    function handleCategorySelect(parameter: string){
        props.onSearch(searchTerm, parameter)
        setSearchCategory(parameter)
    }

    function handleOrder(){

    }

    function handleChangeOrder(){

    }

    return (
        <div id="admin-product-list-header-main">
            <div id="admin-product-list-header-searchfield">
                <SearchField
                    placeholder="Insira o nome do produto"
                    onValueChange={handleSearch}
                    value={searchTerm}
                />
            </div>
            <div id="admin-product-list-header-category-filter">
                <Dropdown
                    onSelect={handleCategorySelect}
                    options={categories}
                    defaultOption={"todos"}
                />
            </div>
            <div id="admin-product-list-header-order-filter">
                <Dropdown
                    placeholder="Ordenar por"
                    onSelect={handleOrder}
                    options={orderTypes}
                    orderSelection={true}
                    onChangeOrder={handleChangeOrder}
                />
            </div>
            <div id="admin-product-list-header-new-product">
                <Link id="admin-product-list-header-new-product-btn" to={"/admin/produtos/novo"}>
                    Novo Produto
                </Link>
            </div>
        </div>
    )
}