import React, {useState, useEffect, ChangeEvent} from "react"

import "./imageUpload.css"


export default function ImageUpload(props: {parent?: string, onUploadImg: Function}){


    // function handleFileChange(event: ChangeEvent<HTMLInputElement>){
    function handleFileChange(files: any){
        console.log(files)
        const file = files[0];
        if (file) {
            console.log("leitor", file)
            props.onUploadImg(file)
        }
    };

    function teste(){
        document.getElementById(`file-upload-field${props.parent}`)?.click()
    }

    return (
        <div id="file-upload-main">
            <input 
                id={`file-upload-field${props.parent}`}
                style={{display: "none"}} 
                type="file" 
                onChange={(e) => {handleFileChange(e.target.files)}}
            />
            <div id="file-upload-btn" onClick={teste}>
                <p id="file-upload-btn-txt">
                    Adicionar Imagem
                </p>
            </div>
        </div>
    );
}