import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"

import "./header.css"

import Logo          from "assets/logo/logoTransparentBackground2.png"
import SearchField   from "../searchField/searchField"
import HeaderMenu    from "./components/menu/headerMenu"
import Cart          from "./components/cart/cart"
import User          from "./components/user/user"
import Unauthenticated from "./components/unauthenticated/unauthenticated"
import Admin         from "./components/admin/admin"
import MenuBtn       from "./components/menuBtn/menuBtn"
import { ICategory } from "interfaces/product/IProduct"
import { AuthContext } from "context/authProvider"

export default function Header(){

    const authContext = useContext(AuthContext)

    const [renderMobileMenu, setRenderMobileMenu] = useState<boolean>(false)

    const [categories, setCategories] = useState<ICategory[]>([
        {
            main: "menino",
            sub: [
                "conjunto",
                "tshirt",
                "calça",
                "short",
                "social" ,
                "inverno" ,
                "moda praia",
                "fantasias",
                "cuecas"
            ]
        },
        {
            main: "menina",
            sub: [
                "conjunto",
                "macacão" ,
                "vestido" ,
                "short",
                "saia", 
                "blusa",
                "inverno",
                "moda praia",
                "fantasia",
                "calcinha"
            ]
        },
        {
            main: "acessórios",
            sub: [
                "Brinco",
                "Pulseira",
                "Tiara",
                "Colar",
                "Bolsa",
                "Presilha",
                "Boné",
                "Cinto" ,
                "Outros",
            ]
        }
    ])

    useEffect(() => {
        const handleResize = () => {
            const rootElement = document.getElementById("root")
            const headerElement = document.getElementById("header-main")

            if (rootElement && headerElement) {
                const rootHeight = rootElement.getBoundingClientRect().height
                const headerHeight = headerElement.getBoundingClientRect().height
                const newContentHeight = rootHeight - headerHeight

                const contentElement = document.getElementById("header-mobile-menu")
                if (contentElement) {
                    contentElement.style.height = `${newContentHeight}px`
                }
            }
        }

        handleResize()
        window.addEventListener("resize", handleResize)

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    function handleSearchFieldChange(value: string){
    }

    function handleRenderMobileMenu(){
        if (renderMobileMenu) {
            setRenderMobileMenu(false)
        } else {
            setRenderMobileMenu(true)
        }
    }

    return (
        <>
            <div id="header-main">
                <div id="header-shadow">
                    <Link id="header-logo-link" to="/#" draggable={false}>
                        <img id="header-logo" src={Logo} alt="" draggable={false}/>
                    </Link>
                    {/* <div id="header-logo-container">
                        <a id="header-logo-link" href="/#" draggable={false}>
                            <img id="header-logo" src={Logo} alt="" draggable={false}/>
                        </a>
                    </div> */}
                    <div id="header-general-fields-container">
                        <div id="header-general-fields-search">
                            <div id="header-general-fields-search-container">
                                <SearchField onValueChange={handleSearchFieldChange} placeholder="O que você precisa?"/>
                            </div>
                        </div>
                        <div id="header-general-fields-category-container">
                            <HeaderMenu
                                categoryName="meninos"
                                entries={categories[0].sub}
                                hoverColor="blue"
                            />
                            <HeaderMenu
                                categoryName="meninas"
                                entries={categories[1].sub}
                                hoverColor="pink"
                            />
                            {/* <HeaderMenu
                                categoryName="estações"
                            /> */}
                            <HeaderMenu
                                categoryName="promoções"
                            />
                            <HeaderMenu
                                categoryName="acessórios"
                                entries={categories[2].sub}
                            />
                        </div>
                    </div>
                    <div id="header-user-fields-container">
                        {/* <Admin/> */}
                        {   
                            !authContext?.verify() &&
                            (
                                <Unauthenticated/>
                            )
                        }
                        {
                            authContext?.verify() &&
                            (
                                <User/> 
                            )
                        }
                        <MenuBtn onClick={handleRenderMobileMenu}/>
                        <Cart/>
                    </div>
                </div>
            </div>
            <div id="header-mobile-menu" className={renderMobileMenu? "header-mobile-menu-show": "header-mobile-menu-hide"}>
                <div id="header-mobile-menu-search-container">
                    <div id="header-mobile-menu-search">
                        <SearchField 
                            onValueChange={handleSearchFieldChange} 
                            placeholder="O que você precisa?"
                            showIcon={false}
                        />
                    </div>
                </div>
                <ul id="header-mobile-menu-categories-container">
                        {
                            categories.map((category) => {
                                return (
                                    <li key={category.main} className="header-mobile-menu-category-container">
                                        <ul className="header-mobile-menu-category">
                                            <li key={200} className="header-mobile-menu-category-title">
                                                <p className="header-mobile-menu-category-title-txt">
                                                    {category.main}
                                                </p>
                                            </li>
                                            {
                                                category.sub.map((sub) => {
                                                    return (
                                                        <li key={sub} className="header-mobile-menu-category-entry">
                                                            <p className="header-mobile-menu-category-entry-txt">
                                                                {sub}
                                                            </p>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                )   
                            })
                        }
                        {/* <ul className="header-mobile-menu-category">
                            <li className="header-mobile-menu-category-title">
                                <p className="header-mobile-menu-category-title-txt">
                                    Menino
                                </p>
                            </li>
                            <li className="header-mobile-menu-category-entry">
                                <p className="header-mobile-menu-category-entry-txt">
                                    Conjunto
                                </p>
                            </li>
                            <li className="header-mobile-menu-category-entry">
                                <p className="header-mobile-menu-category-entry-txt">
                                    Tshirt
                                </p>
                            </li>
                        </ul> */}
                    <li className="header-mobile-menu-category-container">

                    </li>
                </ul>
            </div>
        </>
    )
}