import React, { useState, useEffect, useContext } from "react"

import Lottie            from "lottie-react"
import loadAnimation     from "assets/animations/load2.json"

export default function LoadingAnimation(props: {style: React.CSSProperties}){

    return (
        <Lottie
            animationData={loadAnimation}
            autoplay={true}
            loop={true}
            onDOMLoaded={() => {}}
            onComplete={() => {}}
            style={props.style}
        />
    )
}