import React, {useContext, useEffect, useState} from "react"

import "./personalDataEdit.css"

import { ProfileDataContext } from "../../userPersonalData"
import TextFieldUnderline from "components/inputs/textfieldUnderline/textfieldUnderline"
import Validate from "misc/validate"

interface IPersonalDataEditError {
    field1: string
    field2: string
    field3: string
}

export default function PersonalDataEdit(){

    const profileDataContext = useContext(ProfileDataContext)

    const [error, setError] = useState<IPersonalDataEditError>({
        field1: "",
        field2: "",
        field3: ""
    })

    const [fieldName, setFieldName] = useState<string>("")
    
    const [value, setValue] = useState<string>("")
    const [newPassword, setNewPassword] = useState<string>("")
    const [confirmNewpassword, setConfirmNewPassword] = useState<string>("")

    useEffect(() => {
        console.log(profileDataContext?.fieldToEdit)

        switch (profileDataContext?.fieldToEdit) {
            case "name":
                setFieldName("nome")
                break
            case "password":
                setFieldName("senha")
                break
            case "email":
                setFieldName("email")
                break
            case "CPF":
                setFieldName("CPF")
                break    
            case "phone":
                setFieldName("telefone")
                break 
            default:
                setFieldName("")
                break;
        }

    },[])

    useEffect(() => {
        if (error.field1 !== ""){
            setValue(error.field1)
        }
    },[error.field1])

    useEffect(() => {
        if (error.field2 !== ""){
            setNewPassword(error.field2)
        }
    },[error.field2])

    useEffect(() => {
        if (error.field3 !== ""){
            setConfirmNewPassword(error.field3)
        }
    },[error.field3])

    function handleValueChange(value: string){
        setValue(value)
    }
    
    function handleNewPassword(value: string){
        setNewPassword(value)
    }

    function handleConfirmNewPassword(value: string){
        setConfirmNewPassword(value)
    }

    function handleSaveEditField(){
        if (profileDataContext?.fieldToEdit === "email"){
            const validate = new Validate()
            const isEmail = validate.validateEmail(value)

            if (!isEmail){
                setError((prev) => ({
                    ...prev,
                    field1: "email inválido",
                }))
            }
        } 
        else if (profileDataContext?.fieldToEdit === "password"){
            if (value === "" || newPassword === "" || confirmNewpassword === ""){
                if (value === ""){
                    setError((prev) => ({
                        ...prev,
                        field1: "senha inválida",
                    }))
                }
                if (newPassword === ""){
                    setError((prev) => ({
                        ...prev,
                        field2: "senha inválida",
                    }))
                }
                if (confirmNewpassword === ""){
                    setError((prev) => ({
                        ...prev,
                        field3: "senha inválida",
                    }))
                }
            }
            else if (newPassword === confirmNewpassword){
                if (newPassword.length < 8){
                    setError((prev) => ({
                        ...prev,
                        field2: "senha fraca",
                    }))
                }
                /* chamada da api para verificar se a senha passada é a de fato */
            }
            else {
                setNewPassword("erro deu ruim")
                setError((prev) => ({
                    ...prev,
                    field2: "as senhas nao são iguais",
                    field3: "as senhas nao são iguais"
                }))
            }
        }
        else if (profileDataContext?.fieldToEdit === "name") {
            if (value === ""){
                setError((prev) => ({
                    ...prev,
                    field1: "não é possivel alterar para um nome vazio",
                }))
            }
        }
    }

    function handleCancelEditField(){
        profileDataContext?.setFieldToEdit("")
        profileDataContext?.navigate("data")
    }

    function handleResetError(){
        if (error.field1 !== ""){
            setValue("")
        }
        if (error.field2 !== ""){
            setNewPassword("")
        }
        if (error.field3 !== ""){
            setConfirmNewPassword("")
        }

        setError(
            {
                field1: "",
                field2: "",
                field3: ""
            }
        )
    }

    return (
        <div id="user-profile-personal-data-edit-content">
            <div id="user-profile-personal-data-edit-label">
                {   
                    fieldName &&
                    (
                        <p id="user-profile-personal-data-edit-label-txt">
                            {fieldName === "senha"? "Insira os dados para alterar a senha": `insira os dados para alterar o ${fieldName}`}
                        </p>

                    )
                }
            </div>
            <div id="user-profile-personal-data-edit-field">
                <div id="user-profile-personal-data-edit-text">
                    <TextFieldUnderline
                        label={fieldName}
                        onValueChange={handleValueChange}
                        error={error.field1}
                        onSelected={handleResetError}
                        value={value}
                    />
                </div>
                {   
                    fieldName === "senha" &&
                    (
                        <>
                            <div className="user-profile-personal-data-edit-text2">
                                <TextFieldUnderline
                                    label="nova senha"
                                    onValueChange={handleNewPassword}
                                    error={error.field2}
                                    onSelected={handleResetError}
                                    value={newPassword}
                                />
                            </div>
                            <div className="user-profile-personal-data-edit-text2">
                                <TextFieldUnderline
                                    label="repita a nova senha"
                                    onValueChange={handleConfirmNewPassword}
                                    error={error.field3}
                                    onSelected={handleResetError}
                                    value={confirmNewpassword}
                                />
                            </div>
                        </>
                    )
                }
            </div>
            <div id="user-profile-personal-data-edit-btn">
                <p 
                    id="user-profile-personal-data-edit-btn-save"
                    onClick={handleSaveEditField}
                >
                    Salvar
                </p>
                <p 
                    id="user-profile-personal-data-edit-btn-cancel"
                    onClick={handleCancelEditField}    
                >
                    Cancelar
                </p>
            </div>
        </div>
    )
}