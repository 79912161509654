import React, { useState, useEffect, createContext, useContext, Dispatch } from "react"

import "./userCart.css"

import UserProfileTemplate from "../components/userPageTemplate/userPageTemplate"
import StatusTracker       from "./components/statusTracker/statusTracker"
import Checkout            from "./components/checkout/checkout"
import Authentication      from "./components/authentication/authentication"
import Address             from "./components/address/address"
import Payment             from "./components/payment/payment"
import LoadingAnimation from "components/animations/loadingAnimation"
import CartImage           from "assets/icons/pages/userProfile/cart-withouth-background.png"
import { AuthContext }     from "context/authProvider"

import IStatusTracker      from "interfaces/IStatusTracket"
import IShipmentAddress    from "interfaces/IShipmentAddress"
import IClient             from "interfaces/IClient"
import { IOrder }          from "interfaces/cart/IOrder"
import { IProductCart }    from "interfaces/product/IProductCart"


interface INavigation {
    checkout: boolean
    auth:     boolean
    address:  boolean
    payment:  boolean
}

export interface ICartContext {
    order: IOrder,
    setOrder: Dispatch<React.SetStateAction<IOrder>>
    setStatusTracker: Dispatch<React.SetStateAction<IStatusTracker>>
    handleNavigation: Function
}

export const CartContext = createContext<ICartContext | undefined>(undefined);

export default function UserCart(){

    const authContext = useContext(AuthContext)

    const [path, setPath] = useState<string>("")
    
    const [navigationController, setNavigationController] = useState<INavigation>({
        checkout: true,
        auth:     false,
        address:  false,
        payment:  false
    })

    const [statusTracker, setStatusTracker] = useState<IStatusTracker>({
        checkout: false,
        auth:     false,
        address:  false,
        payment:  false
    })

    const [order, setOrder] = useState<IOrder>({
        userId: "",
        addressId: ""
    })

    useEffect(() => {
        if (authContext?.verify()) {
            setPath("/perfil/carrinho")
        } else {
            setPath("/carrinho")
        }
    },[authContext?.isAuthenticated])

    function handleNavigation(element: string){
        for (const key in navigationController){
            if (navigationController.hasOwnProperty(key)){
                const keyTyped = key as keyof INavigation

                if (keyTyped === element){
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: true
                    }))
                } else {
                    setNavigationController((previous) => ({
                        ...previous,
                        [keyTyped]: false
                    }))
                }
            }
        }
    }

    return (
        <CartContext.Provider value={
            {
                order: order,
                setOrder: setOrder,
                setStatusTracker: setStatusTracker,
                handleNavigation: handleNavigation
            }
        }
        >
            <UserProfileTemplate image={CartImage} name="Carrinho" navigationUrl={path} key={"cart"}>
                <div id="user-profile-cart-status-tracker">
                    <StatusTracker data={statusTracker}/>
                </div>
                {
                    navigationController.checkout &&
                    (
                        <Checkout/>
                    )
                }
                {
                    navigationController.auth &&
                    (
                        <Authentication/>
                    )
                }
                {   
                    navigationController.address &&
                    (
                        <Address/>
                    )
                }
                {   
                    navigationController.payment &&
                    (
                        <Payment/>
                    )
                }
            </UserProfileTemplate>
        </CartContext.Provider>
    )
}