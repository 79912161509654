import React, {useState, useEffect, ReactNode, createContext} from "react"

import { IProductCart } from "interfaces/product/IProductCart"

interface ICartContext {
    addProduct: Function
    getProducts: Function
    increaseQuantity: Function
    decreaseQuantity: Function
    productQuantity: number
}

export const CartStorageContext = createContext<ICartContext | undefined>(undefined);

export default function CartStorageProvider(props: {children?: ReactNode}): JSX.Element{

    const [productQuantity, setProductQuantity] = useState<number>(0)

    useEffect(() => {
        handleGetProductQuantity()
    },[])


    function handleGetProductQuantity(){
        var cart = localStorage.getItem("cart")
        if (cart){
            var counter: number = 0
            var parsedCart: {quantity: number, data: IProductCart}[] = JSON.parse(cart)
            
            parsedCart.forEach((cartProduct) => {
                counter = counter + cartProduct.quantity
            })
            setProductQuantity(counter)
        }
    }

    function handleAddProduct(data: IProductCart){
        if (localStorage.getItem("cart") === null){
            localStorage.setItem("cart", JSON.stringify(
                [
                    {   
                        quantity: 1,
                        data
                    }
                ]
            ))
        } else {
            var cart = localStorage.getItem("cart")
            if (cart){
                var isNew: boolean = true
                var parsedCart: {quantity: number, data: IProductCart}[] = JSON.parse(cart)
                parsedCart.forEach((cardProduct) => {
                    if (
                        cardProduct.data.id === data.id && 
                        cardProduct.data.variation.id === data.variation.id &&
                        cardProduct.data.variation.size === data.variation.size
                    ){
                        cardProduct.quantity = cardProduct.quantity + 1
                        isNew = false
                    }
                })

                if (isNew){
                    parsedCart.push({ quantity: 1, data })
                }

                localStorage.setItem("cart", JSON.stringify(parsedCart))
            }
        }
        setProductQuantity(productQuantity + 1)
    }

    function handleRemoveProduct(id: string, variationId: string, size: string){
        var cart = localStorage.getItem("cart")
        if (cart){
            var parsedCart: {quantity: number, data: IProductCart}[] = JSON.parse(cart)
            const a = parsedCart.filter((cartProduct) => {
                if (
                    cartProduct.data.id !== id || 
                    cartProduct.data.variation.id !== variationId ||
                    cartProduct.data.variation.size !== size
                ){
                    return cartProduct
                } else {
                    setProductQuantity(productQuantity - cartProduct.quantity)
                }
            })
            localStorage.setItem("cart", JSON.stringify(a))
        }
    }

    function handleGetProduct(){
        if (localStorage.getItem("cart") !== null){
            var cart = localStorage.getItem("cart")
            if (cart){
                var parsedCart: {quantity: number, data: IProductCart}[] = JSON.parse(cart)

                return parsedCart
            }
            else {
                return []
            }
        } else {
            return []
        }
    }

    function handleDecreaseQuantity(id: string, variationId: string, size: string){
        var cart = localStorage.getItem("cart")
        if (cart){
            var parsedCart: {quantity: number, data: IProductCart}[] = JSON.parse(cart)
            parsedCart.forEach((cartProduct) => {
                if (
                    cartProduct.data.id === id && 
                    cartProduct.data.variation.id === variationId &&
                    cartProduct.data.variation.size === size
                ){
                    cartProduct.quantity = cartProduct.quantity - 1
                }
            })

            const a = parsedCart.filter((cartProduct) => {
                if (cartProduct.quantity >= 1){
                    return cartProduct
                }
            })

            localStorage.setItem("cart", JSON.stringify(a))
            setProductQuantity(productQuantity - 1)
        }
    }

    function handleIncreaseQuantity(id: string, variationId: string, size: string){
        var cart = localStorage.getItem("cart")
        if (cart){
            var parsedCart: {quantity: number, data: IProductCart}[] = JSON.parse(cart)
            parsedCart.forEach((cartProduct) => {
                if (
                    cartProduct.data.id === id && 
                    cartProduct.data.variation.id === variationId &&
                    cartProduct.data.variation.size === size
                ){
                    cartProduct.quantity = cartProduct.quantity + 1
                }
            })

            localStorage.setItem("cart", JSON.stringify(parsedCart))
            setProductQuantity(productQuantity + 1)
        }
    }
    

    return (
        <CartStorageContext.Provider value={
            {
                addProduct: handleAddProduct,
                getProducts: handleGetProduct,
                increaseQuantity: handleIncreaseQuantity,
                decreaseQuantity: handleDecreaseQuantity,
                productQuantity: productQuantity
            }
        }>
            {props.children}
        </CartStorageContext.Provider>
    )
}