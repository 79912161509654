import React, {useState, useEffect, createContext, Dispatch} from "react"
import { useNavigate } from "react-router-dom"

import "./product.css"

import CheckBox          from "components/inputs/checkbox/checkbox"
import Header            from "./components/header/header"
import Dropdown          from "components/inputs/dropdown/dropdown"
import Variation         from "./components/variation/variation"
import Navigation        from "./components/navigation/navigation"
import AdminPageTemplate from "pages/admin/components/adminPageTemplate/adminPageTemplate"
import { apiCaller } from "api/ApiCaller"

import { IProduct, ICategory} from "interfaces/product/IProduct"

interface IProductContext {
    variationIndex: number
    setVariationIndex: Dispatch<React.SetStateAction<number>>
	product: IProduct
	setProduct: Dispatch<React.SetStateAction<IProduct>>
    error: string
    setError: Dispatch<React.SetStateAction<string>>
}

export const ProductContext = createContext<IProductContext | undefined>(undefined);

export default function AdminProduct(props: {data?: IProduct}){

    const navigate = useNavigate()

    const [disableCheckbox,        setDisableCheckbox       ] = useState<boolean>(true)
    const [error,                  setError                 ] = useState<string>("")
    const [categories,             setCategories            ] = useState<ICategory[]>([])
    const [specialCategories,      setSpecialCategories     ] = useState<string[]>([])
    const [selectedCategory,       setSelectedCategory      ] = useState<ICategory>()
    const [selectedVariationIndex, setSelectedVariationIndex] = useState<number>(0)

    const [product, setProduct] = useState<IProduct>(
        {
            id: "",
            name: "",
            url: "",
            enabled: false,
            featured: false,
            primaryCategory: "",
            secondaryCategory: "",
            variations: [],
            description: ""
        }
    )

    useEffect(() => {

        setCategories(
            [
                {
                    main: "menino",
                    sub: [
                        "conjunto",
                        "tshirt",
                        "calça",
                        "short",
                        "social" ,
                        "inverno" ,
                        "moda praia",
                        "fantasias",
                        "cuecas"
                    ]
                },
                {
                    main: "menina",
                    sub: [
                        "conjunto",
                        "macacão" ,
                        "vestido" ,
                        "short",
                        "saia", 
                        "blusa",
                        "inverno",
                        "moda praia",
                        "fantasia",
                        "calcinha"
                    ]
                }
            ]
        )

        setSpecialCategories(
            [
                "nenhuma",
                "dia das mães",
                "pascoa",
                "natal"
            ]
        )

    },[])

    useEffect(() => {
        if (categories){

            if (props.data){
                setProduct(props.data)
                setupMainCategory()
            }
        }
    },[categories])

    useEffect(() => {
            let haveEnabledVariation = false
    
            product.variations.forEach((variation) => {
                if (variation.enabled){
                    haveEnabledVariation = true
                }
            })
    
            if (product.name === ""){
                handleProductEnable(false)
                setDisableCheckbox(true)
            }
            else if (haveEnabledVariation === false){
                handleProductEnable(false)
                setDisableCheckbox(true)
            } 
            else if (product.primaryCategory === "") {
                handleProductEnable(false)
                setDisableCheckbox(true)
            }
            else if (product.secondaryCategory === ""){
                handleProductEnable(false)
                setDisableCheckbox(true)
            } else {
                setDisableCheckbox(false)
            }
    },[product.primaryCategory, product.name, product.secondaryCategory, product.variations])

    function handleChange(field: string, value: string | boolean | number){
        if (field === "primaryCategory" && typeof value === "string"){
            let selectedSubCategories = categories
            .filter((category) => category.main === value)
            .map((category) => category.sub)
            .flat();

            setSelectedCategory({
                main: value,
                sub: selectedSubCategories
            })

            setProduct((prev) => ({
                ...prev,
                primaryCategory: value
            }))
        }

        setProduct((prev) => {
            return {
                ...prev,
                [field]: value
            };
        });
    }

    function handleSave(){
        console.log("================produto==============")
        console.log(product)
        console.log("=====================================")


        navigate("/admin/produtos")
    }

    function handleProductEnable(value: boolean){
        setProduct((prev) => ({
            ...prev,
            enabled: value
        }))
    }

    function setupMainCategory(){

        let sub:string[] = []

        for (let index = 0; index < categories.length; index++) {
            if (categories[index].main === product.primaryCategory){
                sub = categories[index].sub
            }
            
        }
        setSelectedCategory({
            main: product.primaryCategory,
            sub: sub
        })
    }

    function handleCheckboxClick(){
        if (disableCheckbox){
            setError("Erro: para habilitar o produto preencha todos os campos")
        }
    }

    return (
        <AdminPageTemplate navigationUrl="/admin/produtos/novo">
            <ProductContext.Provider 
                value={
                    {
                        product: product, 
                        setProduct: setProduct, 
                        variationIndex: selectedVariationIndex, 
                        setVariationIndex: setSelectedVariationIndex,
                        error: error,
                        setError: setError
                    }
                }>
                <div id="admin-product-main">
                    <Header
                        onSave={handleSave}
                    />
                    <div id="admin-product-body">
                        <div id="admin-product-primary">
                            <div className="admin-product-primary-field">
                                <Dropdown
                                    label="Categoria principal"
                                    showOnClick={true}
                                    options={categories.map((option) => {return option.main})}
                                    onSelect={(value: string) => {handleChange("primaryCategory", value)}}
                                    default={product.primaryCategory}
                                />
                            </div>
                            <div className="admin-product-primary-field">
                                {
                                    selectedCategory &&
                                    (
                                        <Dropdown
                                            label="Categoria secundária"
                                            showOnClick={true}
                                            options={selectedCategory.sub}
                                            onSelect={(value: string) => {handleChange("secondaryCategory", value)}}
                                            default={product.secondaryCategory}
                                        />
                                    )
                                }
                            </div>
                            <div className="admin-product-primary-field">
                                <Dropdown
                                    label="Categoria especial"
                                    hideOnClick={true}
                                    showOnClick={true}
                                    options={specialCategories}
                                    onSelect={() => {}}
                                    default={specialCategories[0]}
                                />
                            </div>
                            <div id="admin-product-primary-checkbox-field1">
                                <CheckBox
                                    text="Habilitar Produto"
                                    disabled={disableCheckbox}
                                    value={product.enabled}
                                    onValueChange={(value: boolean) => {handleChange("enabled", value)}}
                                    onClick={handleCheckboxClick}
                                />
                            </div>
                            <div id="admin-product-primary-checkbox-field2">
                                <CheckBox
                                    text="Destacar"
                                    onValueChange={(value: boolean) => {handleChange("featured", value)}}
                                />
                            </div>
                        </div>
                        <div id="admin-product-variation">
                            <Navigation
                                data={product.variations}
                            />
                            {
                                product.variations[selectedVariationIndex] &&
                                (
                                    <Variation
                                        data={product.variations[selectedVariationIndex]}
                                    />
                                )
                            }
                            {
                                !product.variations[selectedVariationIndex] &&
                                (
                                    <div id="admin-product-variation-placeholder">
                                        <p id="admin-product-variation-placeholder-txt">
                                            Crie uma variação no menu acima
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </ProductContext.Provider>
        </AdminPageTemplate>
    )
}