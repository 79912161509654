import React, {useContext} from "react"

import "./product.css"

import VariationSelector from "./components/variationSelector/variationSelector"

import DeleteIcon from "assets/icons/delete.png"
import EditIcon from "assets/icons/edit2.png"

import { IProduct } from "interfaces/product/IProduct"

export default function Product(props: {data: IProduct}){

    function handleEditProduct(){
        // if (controlPanelContext){
        //     controlPanelContext.setProduct(props.data)
        //     controlPanelContext.handleNavigation("editProduct")
        // }
    }
    
    // function handleDeleteProduct(index: number){
    //     if (controlPanelContext) {
    //         controlPanelContext.setProduct((prevProduct) => {
    //             const updatedVariations = [...prevProduct!.variations];
    //             updatedVariations[productContext.variationIndex].sizes.splice(index, 1);
    //             return {
    //                 ...prevProduct!,
    //                 variations: updatedVariations,
    //             };
    //         });
    //     }
    // }

    return (
        <li id="admin-product-list-product-main">
            <div id="admin-product-list-product-name">
                <p id="admin-product-list-product-name-txt">
                    {props.data.name}
                </p>
            </div>
            <div id="admin-product-list-product-variations">
                <VariationSelector
                    data={props.data.variations}
                />
            </div>
            <div id="admin-product-list-product-categories">
                <p className="admin-product-list-product-categories-txt">
                    {props.data.primaryCategory}
                </p>
                <p className="admin-product-list-product-categories-txt">
                    {props.data.secondaryCategory}
                </p>
            </div>
            <div id="admin-product-list-product-actions">
                <div id="admin-product-list-product-actions-delete">
                    <img 
                        className="admin-product-list-product-actions-img"
                        src={EditIcon}
                        alt="" 
                        onClick={handleEditProduct}
                        draggable={false}
                    />
                </div>
                <div id="admin-product-list-product-actions-edit">
                    <img 
                        className="admin-product-list-product-actions-img"
                        src={DeleteIcon}
                        alt="" 
                        draggable={false}
                    />
                </div>
            </div>
        </li>
    )
}
