import React, {useContext, useEffect, useState} from "react"

import "./userProfileAddressCard.css"

import DefaultAddressIcon from "assets/icons/location.png"
import IShipmentAddress from "interfaces/IShipmentAddress"

export default function UserProfileAddressCard(
    props: {
        data: IShipmentAddress, 
        onSetAsDefault: Function, 
        onDelete: Function,
        onEdit: Function
    }
){


    function handleSetAsDefault(){
        props.onSetAsDefault(props.data.id)
    }

    function handleDelete(){
        props.onDelete(props.data.id)
    }
    
    function handleUpdate(){
        props.onEdit(props.data.id)
    }

    return (
        <li id="user-profile-address-card-main">
            <div id="user-profile-address-card-header">
                <div id="user-profile-address-card-header-name">
                    <p id="user-profile-address-card-header-name-txt"> 
                        {props.data.name}
                    </p>
                </div>
                <div id="user-profile-address-card-header-default-img-container">
                    <img
                        style={{display: (props.data.default? "flex": "none")}}
                        id="user-profile-address-card-header-default-img" 
                        src={DefaultAddressIcon}
                        alt="" 
                        draggable={false}
                    />
                </div>
            </div>
            <div id="user-profile-address-card-body">
                <p className="user-profile-address-card-body-txt">
                    {props.data.name}
                </p>
                <div className="user-profile-address-card-body-txt-container">
                    <p className="user-profile-address-card-body-txt-contained">
                        {props.data.address1}
                    </p>
                    <p className="user-profile-address-card-body-txt-contained">
                        {props.data.unitNumber}
                    </p>
                </div>
                <p className="user-profile-address-card-body-txt"> 
                    {props.data.complement}
                </p>
                <p className="user-profile-address-card-body-txt"> 
                    {props.data.address2}
                </p>
                <div className="user-profile-address-card-body-txt-container">
                    <p className="user-profile-address-card-body-txt-contained">
                        {props.data.city}
                    </p>
                    <p className="user-profile-address-card-body-txt-contained">
                        {props.data.state}
                    </p>
                </div>
                <p className="user-profile-address-card-body-txt">
                    {props.data.postalCode}
                </p>
            </div>
            <div id="user-profile-address-card-footer">
                <p 
                    className="user-profile-address-card-footer-btns"
                    onClick={handleUpdate}
                >
                    Alterar
                </p>
                <p 
                    className="user-profile-address-card-footer-btns"
                    onClick={handleDelete}
                >
                    Excluir
                </p>
                {
                    !props.data.default &&
                    (
                        <p 
                            className="user-profile-address-card-footer-btns" 
                            onClick={handleSetAsDefault}
                        >
                            Definir como padrão
                        </p>

                    )
                }
            </div>
        </li>
    )
}