import React from "react"
import Routes from "./routes"

import CartStorageProvider from "context/cartStorageProvider"
import AuthProvider from "context/authProvider"

function App() {
  	return (
		<AuthProvider>
			<CartStorageProvider>
				<Routes></Routes>
			</CartStorageProvider>
		</AuthProvider>
	)
}

export default App;