import React, { useState, useEffect, useContext } from "react"

import "./access.css"

import { AuthContext } from "context/authProvider"
import { useNavigate } from "react-router-dom"
import Login    from "./components/login/login"
import Register from "./components/register/register"

interface INavigation {
    login: boolean;
    register: boolean
}

export default function Access(){

    const authContext = useContext(AuthContext)
    const navigate = useNavigate()

    const [navigationController, setNavigationController] = useState<INavigation>({
        login: true,
        register: false
    })

    useEffect(() => {
        /* if the user is already logged in, they're redirected to main page */
        if (authContext?.getToken() !== ""){
            navigate("/")
        }
    },[])

    function handleNavigation(value: string){
        if (value === "register"){
            setNavigationController({
                login: true,
                register: false
            })
        }
        if (value === "login"){
            setNavigationController({
                login: false,
                register: true
            })
        }
    }

    return (
        <div id="access-main">
            {
                navigationController.login &&
                (
                    <Login onChangeModal={handleNavigation}/>
                )
            }
            {
                navigationController.register &&
                (
                    <Register onChangeModal={handleNavigation}/>
                )
            }
        </div>
    )
}