import React, {useContext} from "react"

import "./productEditNavigationBtn.css"

import WarningIcon          from "assets/icons/warning.png"
import { IProductVariation} from "interfaces/product/IProduct"
import { ProductContext } from "pages/admin/product/product/product" 

export default function NavigationBtn(props: {variation: IProductVariation, index: number}) {

    const productContext = useContext(ProductContext)
    
    function handleVariationSelect(){
        if (productContext){
            productContext.setVariationIndex(props.index)
        }
    }
    
    return (
        <li 
            id="product-edit-navigation-btn-main" 
            onClick={handleVariationSelect}
            style={{backgroundColor: (props.index === productContext?.variationIndex? "var(--light-gray)": "")}}
        >
            <div id="product-edit-navigation-btn-name">
                <p id="product-edit-navigation-btn-name-txt">
                    Variação
                </p>
                <div id="product-edit-navigation-btn-color" style={{backgroundColor: `#${props.variation.color}`}}>

                </div>
            </div>
            <div id="product-edit-navigation-btn-notification">
                {/* <img 
                    id="product-edit-navigation-btn-notification-img"
                    src={WarningIcon} 
                    alt="" 
                    draggable={false}
                /> */}
            </div>
        </li>
    )
}