import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from 'context/authProvider';


export default function UserProtectedRoute(children: any){

    const authContext = useContext(AuthContext)

    if (authContext?.verify() === false) {
        return <Navigate to="/login" replace />;
    } else {
        return <Outlet />;
    }

};
