import React, {useState, useEffect, useContext} from "react"

import "./checkout.css"

import UserProfileCartCard    from "./components/userProfileCartCard"
import { apiCaller } from "api/ApiCaller"
import { IProductCart }       from "interfaces/product/IProductCart"
import { CartContext }        from "pages/user/cart/userCart"
import { CartStorageContext } from "context/cartStorageProvider"
import { AuthContext }        from "context/authProvider"
import { AxiosError, AxiosResponse } from "axios"

export default function CartCheckout(props: {onCheckout?: Function}){

    const authContext        = useContext(AuthContext)
    const cartStorageContext = useContext(CartStorageContext)
    const cartContext        = useContext(CartContext)

    const [products, setProducts] = useState<{quantity: number, data: IProductCart}[]>([])
    const [total, setTotal] = useState<string>("")

    useEffect(() => {
        cartContext?.setStatusTracker((prevStatus) => ({
            ...prevStatus,
            checkout: true
        }))

        if (cartStorageContext){
            setProducts(cartStorageContext.getProducts())
        }
            
    },[cartStorageContext?.productQuantity])
    
    useEffect(() => {
        const totalPrice = products.reduce((accumulator, product) => {
            if (product.data.variation.promotionEnabled){
                return accumulator + (product.data.variation.promotionPrice * product.quantity)
            } else {
                return accumulator + (product.data.variation.price * product.quantity)
            }
        }, 0)

        setTotal(totalPrice.toFixed(2))
    },[products])

    function handleCheckout(){

        if (!authContext?.verify()){
            cartContext?.setStatusTracker((prevStatus) => ({
                ...prevStatus,
                auth: true
            }))
    
            cartContext?.handleNavigation("auth")
        } else {
            cartContext?.setStatusTracker((prevStatus) => ({
                ...prevStatus,
                auth: true,
                address: true
            }))
    
            apiCaller.get("/accounts/", {
                headers: {
                    "Authorization": `Bearer ${authContext?.getToken()}`
                }
            }).then((response: AxiosResponse) => {
                cartContext?.setOrder((prev) => ({
                    ...prev,
                    userId: response.data.data.id
                }))
                
            }).catch((error: AxiosError) => {
                // authContext?.rmToken()
            })

            cartContext?.handleNavigation("address")
        }

        // cartContext?.setOrder((prevOrder) => ({
        //     ...prevOrder,
        //     products: products
        // }))
    }
    
    function handleDelete(id: string, idVariation: string, size: string){
        const filteredProducts = products.filter(product => {
            if (product.data.id !== id || product.data.variation.id !== idVariation || product.data.variation.size !== size){
                return product
            }
        })

        setProducts(filteredProducts)
    }

    return (
        <div id="cart-checkout-main">
            {   
                products.length >= 1 &&
                (
                    <>
                        <div id="cart-checkout-primary">
                            <div id="cart-checkout-product-list-btns">

                            </div>
                            <ul id="cart-checkout-product-list">
                                {
                                    products.map(product => {
                                        return (
                                            <UserProfileCartCard
                                                key={`${product.data.id} ${product.data.variation.id} ${product.data.variation.size}`}
                                                product={product}
                                                onDelete={handleDelete}
                                            />
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div id="cart-checkout-content-secondary">
                            <div id="cart-checkout-content-sub-total">
                                <div id="cart-checkout-content-subtotal-info-container">
                                    <p id="cart-checkout-content-sub-total-info-label-txt">
                                        Sub Total
                                    </p>
                                    <p id="cart-checkout-content-sub-total-info-value-txt">
                                        R$ {total.replace(".", ",")}
                                    </p>
                                </div>
                                <div id="cart-checkout-content-subtotal-btn-container">
                                    <div id="cart-checkout-content-sub-total-btn" onClick={handleCheckout}>
                                        <p id="cart-checkout-content-sub-total-btn-txt">
                                            Fechar Pedido
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            {
                products.length < 1 &&
                (
                    <div id="cart-checkout-empty-cart">
                        <p id="cart-checkout-empty-cart-message-txt">
                            Seu carrinho está vazio
                        </p>
                    </div>
                )
            }
        </div>
    )
}